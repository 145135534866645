import React, { useEffect, useRef } from 'react';
import TransportList from '../../components/transport-list';
import { useSelector } from 'react-redux';
import { useSocket } from '../locator/use-socket';
import { useHistory } from 'react-router-dom';

const IndexPage = () => {

  const isFirst = useRef(true);
  const history = useHistory();
  const { onlineMarkers, currentTransport } = useSelector((state) => state);

  useEffect(() => {
    if (isFirst.current) {
    } else if (currentTransport?._id) {
      history.push(`locator/${currentTransport._id}`);
    }
    isFirst.current = false;

  }, [currentTransport]);

  useSocket();

  return (
    <div>
      <TransportList
        emptyMessage='Нет транспортных средств в онлайне'
        modelName='transport'
        onlineMarkers={onlineMarkers}
      />
    </div>
  );
};

export default IndexPage;
