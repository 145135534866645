import _ from 'lodash';

export const reducer = (state, action) => {
  let index;

  switch (action.type) {
    case 'SET_MAP_POINT':
      return {
        ...state,
        mapPoint: action.value.mapPoint,
        mapZoom: action.value.mapZoom,
      };

    case 'MEASURE_MODE':
      return {
        ...state,
        measureMode: action.value,
      };

    case 'LOAD_ROUTES':
      return {
        ...state,
        routes: action.value,
      };
    case 'CREATE_ROUTE':
      return {
        ...state,
        routes: [...state.routes, action.value],
      };

    case 'REMOVE_ROUTE':
      return {
        ...state,
        routes: state.routes.filter((route) => route._id !== action.value.id),
      };

    case 'UPDATE_ROUTE':
      index = state.routes.findIndex((route) => route._id === action.value.id);

      if (index === -1) {
        index = state.routes.findIndex((route) => route._id === action.value.route._id);
      }
      return {
        ...state,
        routes: [
          ...state.routes.slice(0, index),
          action.value.route,
          ...state.routes.slice(index + 1),
        ],
      };

    case 'SET_CURRENT_ROUTE':
      return {
        ...state,
        currentRoute: { ...action.value },
      };

    case 'SET_CURRENT_ROUTE_BY_ID':
      const currentRoute = state.routes.find((r) => r._id === action.value);
      if (!currentRoute) {
        return state;
      }

      return {
        ...state,
        currentRoute,
      };

    default:
      return state;
  }
};
