/*global google*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map, GoogleApiWrapper, InfoWindow, Marker, Polyline } from 'google-maps-react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

import { sprintDurationWith, betweenDuration } from '../../utils/datetime';
import { trackColor } from '../../utils/speed';
import LoaderProgress from '../../components/circular-progress-center';

import StopIcon from './icons/stop.png';
import ParkingIcon from './icons/parking.png';

const LoadingContainer = (props) => <LoaderProgress justify="center" alignItems="center" />;

const LAT = 1;
const LNG = 0;

const FIRST = 0;

const YOUR_GOOGLE_API_KEY_GOES_HERE = process.env.REACT_APP_GOOGLE_KEY;

const Markers = (props) => {
  return props.data.map((chunk, index, chunks) => {
    const { chunk: currentChunk, averageSpeed } = chunk;

    const firstPointOfChunk = currentChunk[0];
    const lastPointOfChunk = currentChunk[currentChunk.length - 1];

    if (firstPointOfChunk.speed === 0) {
      let durationBetweenChunks = 0;

      if (index !== FIRST) {
        const prevChunk = chunks[index - 1].chunk;
        durationBetweenChunks = betweenDuration(
          prevChunk[prevChunk.length - 1].gps_timestamp,
          firstPointOfChunk.gps_timestamp,
        );
      }
      const { print: durationText, minutes: minDuration } = sprintDurationWith(
        firstPointOfChunk.gps_timestamp,
        lastPointOfChunk.gps_timestamp,
        durationBetweenChunks,
      );

      return (
        <Marker
          {...props}
          key={firstPointOfChunk._id}
          icon={{
            url: minDuration > 2 ? ParkingIcon : StopIcon,
            scaledSize: new google.maps.Size(24, 24),
          }}
          position={{
            lat: firstPointOfChunk.location.coordinates[LAT],
            lng: firstPointOfChunk.location.coordinates[LNG],
          }}
          value={
            moment.utc(firstPointOfChunk.gps_timestamp).local().format('HH:mm DD MMM') +
            ` (${durationText})`
          }
        />
      );
    } else {
      return (
        <Polyline
          {...props}
          key={firstPointOfChunk._id}
          path={currentChunk.map((point) => ({
            lat: point.location.coordinates[LAT],
            lng: point.location.coordinates[LNG],
          }))}
          strokeColor={trackColor(averageSpeed)}
          strokeWeight={2}
          smoothFactor={1}
          geodesic="true"
          icons={[
            {
              icon: {
                path: props.arrow,
              },
              offset: '100%',
              repeat: '100px',
            },
          ]}
        />
      );
    }
  });
};

function TrackLogMap(props) {
  const dispatch = useDispatch();
  const { transports, tracklog, mapPoint, mapZoom: mapZoomOnClick } = useSelector((state) => state);

  const [mapCenter] = useState(
    localStorage.getItem('mapCenter')
      ? JSON.parse(localStorage.getItem('mapCenter'))
      : {
          lat: 49.442816,
          lng: 32.0621061,
        },
  );
  const [activeMarker, setActiveMarker] = useState({});
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [markerMessage, setMarkerMessage] = useState('');

  const [eventMarker, setEventMarker] = useState();

  const [mapZoom] = useState(
    localStorage.getItem('mapZoom') ? parseInt(localStorage.getItem('mapZoom'), 10) : 15,
  );

  useEffect(() => {
    localStorage.setItem('mapZoom', mapZoom);
  }, [mapZoom]);

  useEffect(() => {
    localStorage.setItem('mapCenter', JSON.stringify(mapCenter));
  }, [mapCenter]);

  useEffect(() => {
    const { transportID, lat, lng, date, message } = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (transportID && transports.length !== 0) {
      dispatch({
        type: 'SET_CURRENT_TRANSPORT',
        value: transportID,
      });

      dispatch({
        type: 'setMapPoint',
        value: {
          mapPoint: {
            lat,
            lng,
          },
          mapZoom: 18,
        },
      });

      dispatch({
        type: 'setDate',
        value: moment(date).startOf('day').utc().toISOString(),
      });

      setEventMarker({ lat, lng, message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transports]);

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setShowInfoWindow(true);
    setMarkerMessage(props.value);
  };

  return (
    <Map
      google={props.google}
      zoom={mapZoomOnClick || mapZoom}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      className={'map'}
      initialCenter={mapCenter}
      center={mapPoint || mapCenter}
    >
      {tracklog && (
        <Markers
          data={tracklog}
          arrow={props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW}
          onClick={onMarkerClick}
        />
      )}

      <InfoWindow marker={activeMarker} visible={showInfoWindow}>
        <span>{markerMessage}</span>
      </InfoWindow>

      {eventMarker ? (
        <Marker
          key={'eventMarker'}
          position={{
            lat: eventMarker.lat,
            lng: eventMarker.lng,
          }}
          onClick={onMarkerClick}
          value={eventMarker.message}
        />
      ) : null}
    </Map>
  );
}

export default withRouter(
  GoogleApiWrapper({
    apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
    LoadingContainer: LoadingContainer,
    language: 'ru',
  })(TrackLogMap),
);
