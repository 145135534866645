import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateAPI } from '../../api/use-geozone-api';

export const useCreateGeozone = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useCreateAPI();

  useEffect(() => {
    if (data && isSuccess) {
      dispatch({
        type: 'CREATE_GEOZONE',
        value: data.response,
      });
    }
  }, [dispatch, data, isSuccess]);

  const onCreateGeozone = useCallback(
    (e) => {
      e.features.forEach((poly) => {
        mutate({
          body: { geometry: poly.geometry, name: 'Созданная геозона' },
        });
      });
    },
    [mutate],
  );

  return onCreateGeozone;
};
