import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDeleteAPI } from './use-api';

export const useDeleteTransport = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useDeleteAPI();

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      dispatch({
        type: 'REMOVE_TRANSPORT',
        value: {
          id: data?.response?._id,
        },
      });
    }
  }, [dispatch, isLoading, data, isSuccess]);

  const onDeleteRoute = useCallback(
    (id) => {
      mutate({
        id,
      });
    },
    [mutate],
  );

  return { onDeleteRoute, isLoading, isSuccess };
};
