import React, { useRef, useEffect } from 'react';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LayersIcon from '@material-ui/icons/Layers';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
function CollapseList(props) {
  const { geozone, measureMode, dispatch } = props;

  return (
    <Collapse in={props.open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {geozone.childs.map((childZone) => {
          return (
            <ListItem
              button
              key={childZone._id}
              className={props.classes.nested}
              onClick={() => {
                if (!measureMode) {
                  dispatch({
                    type: 'SET_MAP_POINT',
                    value: { mapPoint: childZone.center, mapZoom: 15 },
                  });

                  dispatch({
                    type: 'SET_CURRENT_CHILD_GEOZONE',
                    value: { parent_id: geozone._id, child_id: childZone._id },
                  });
                }
              }}
            >
              <ListItemIcon>
                <Avatar>
                  <LayersIcon />
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={childZone.name}
                secondary={`${(childZone.area / 10000).toFixed(2)} га`}
              />
            </ListItem>
          );
        })}
      </List>
    </Collapse>
  );
}

function Expander(props) {
  if (props.open) {
    return <ExpandLess />;
  } else {
    return <ExpandMore />;
  }
}
export default function Geozone(props) {
  const { geozone, currentGeozone, measureMode, dispatch } = props;
  const classes = useStyles();

  const ref = useRef();

  useEffect(() => {
    if (_.get(currentGeozone, '_id', null) === geozone._id) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentGeozone]);

  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(!open);
  }

  const childsArea = geozone.childs.reduce((total, current) => {
    return total + current.area;
  }, 0);

  return (
    <React.Fragment>
      <ListItem
        ref={ref}
        button
        selected={_.get(currentGeozone, '_id', null) === geozone._id ? true : false}
        key={geozone._id}
        onClick={() => {
          handleClick();
          if (!measureMode) {
            dispatch({
              type: 'SET_MAP_POINT',
              value: { mapPoint: geozone.center, mapZoom: 15 },
            });

            dispatch({
              type: 'SET_CURRENT_GEOZONE',
              value: geozone,
            });
          }
        }}
      >
        <ListItemAvatar>
          <Avatar style={{ background: geozone.color }}>
            <LayersIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${geozone.name}`}
          secondary={`${((geozone.area - childsArea) / 10000).toFixed(2)} га`}
        />
        {geozone.childs.length !== 0 ? <Expander open={open} /> : null}
      </ListItem>
      {geozone.childs.length !== 0 ? (
        <CollapseList
          dispatch={dispatch}
          open={open}
          classes={classes}
          geozone={geozone}
          measureMode={measureMode}
        />
      ) : null}
    </React.Fragment>
  );
}
