import axios from 'axios';

export const getFieldEndpoint = () => {
  return process.env.REACT_APP_ENDPOINT + '/api/v1/tracklog';
};

export const trackLogAPI = {
  all: async ({ date, transport_id }) => {
    console.log('try load', { date, transport_id });
    if (!date || !transport_id) return [];

    const { data } = await axios.get(
      getFieldEndpoint() + '?transport_id=' + transport_id + '&date=' + date,
    );
    return data[0];
  },
};
