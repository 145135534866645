import { useQuery, useMutation } from 'react-query';
import { transportAPI } from '../../api-controller/transport-controller';
import { NotificationManager } from 'react-notifications';

export const useGetAllAPI = () => {
  const {
    isIdle,
    isLoading,
    error,
    refetch,
    data: transports,
  } = useQuery(['transports'], () => transportAPI.all(), {
    keepPreviousData: true,
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  return { isLoading, isIdle, error, refetch, transports };
};

export const useUpdateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id, transport }) => transportAPI.update({ id, transport }),
    {
      onSuccess: (response) => {
        if (response?.data?.success) {
          NotificationManager.success('', 'Изменения сохраненены!');
        } else {
          NotificationManager.error(
            response?.data?.error,
            'Ошибка изменения транспортного средства!',
          );
        }
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка редактирования транспортного средства!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ transport }) => transportAPI.create({ transport }),
    {
      onSuccess: (response) => {
        if (response?.data?.success) {
          NotificationManager.success('', 'Транспортное средство создано!');
        } else {
          NotificationManager.error(
            response?.data?.error,
            'Ошибка создания транспортного средства!',
          );
        }
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания транспортного средства!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useDeleteAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id }) => transportAPI.delete({ id }),
    {
      onSuccess: (response) => {
        if (response?.data?.success) {
          NotificationManager.success('', 'Транспортное средство удалено!');
        } else {
          NotificationManager.error(error.message, 'Ошибка удаления транспортного средства!');
        }
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка удаления транспортного средства!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
