import axios from 'axios';

export const getRouteEndpoint = () => {
  return process.env.REACT_APP_ENDPOINT + '/api/v1/georoute';
};

export const routeAPI = {
  all: async () => {
    const { data } = await axios.get(getRouteEndpoint());
    return data;
  },

  create: async ({ body }) => {
    const { data } = await axios.post(getRouteEndpoint(), body);
    return data;
  },
  delete: async ({ id }) => {
    const { data } = await axios.delete(getRouteEndpoint() + '/' + id);
    return data;
  },

  update: async ({ id, body }) => {
    const { data } = await axios.put(getRouteEndpoint({ id }) + '/' + id, body);
    return data;
  },
};
