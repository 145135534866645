import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const Progress = (props) => {
  return (
    <Grid container direction="row" {...props}>
      <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />
    </Grid>
  );
};

export default Progress;
