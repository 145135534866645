import React from 'react';

import SatelliteIcon from '@material-ui/icons/Satellite';
import WatchIcon from '@material-ui/icons/WatchLater';
import SignalIcon from '@material-ui/icons/SignalCellularAlt';
import ButteryIcon from '@material-ui/icons/BatteryFull';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import RotateIcon from '@material-ui/icons/RotateRightOutlined';

import NavigationIcon from '@material-ui/icons/Navigation';

import ButteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import SignalUnknownIcon from '@material-ui/icons/SignalCellularConnectedNoInternet4Bar';

import { Tooltip } from '@material-ui/core';
import TimeAgo from 'react-timeago';
import russianStrings from 'react-timeago/lib/language-strings/ru';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(russianStrings);

function getTimeAgo(date) {
  return <TimeAgo date={date} formatter={formatter} />;
}

const accTransportLevels = {
  0: 'silver',
  1: 'red',
  2: 'red',
  3: 'red',
  4: 'red',
  5: 'red',
  6: 'red',
  7: 'red',
  8: 'red',
  9: 'orange',
  10: 'orange',
  11: 'green',
  12: 'green',
  13: 'green',
};
const accGPSLevels = {
  0: 'silver',
  1: 'red',
  2: 'red',
  3: 'orange',
  4: 'green',
  5: 'green',
  6: 'green',
};

const gsmLevels = {
  0: 'silver',
  1: 'red',
  2: 'orange',
  3: 'orange',
  4: 'green',
  5: 'green',
};

const levels = {
  0: 'silver',
  1: 'black',
  2: 'black',
  3: 'black',
  4: 'red',
  5: 'red',
  6: 'red',
  7: 'red',
  8: 'orange',
  9: 'orange',
  10: 'green',
  11: 'green',
  12: 'green',
  13: 'green',
  14: 'green',
  15: 'green',
  16: 'green',
};

export function SateliteStatus(props) {
  const { transport } = props;

  const { details } = transport;

  const { sat } = details;

  return (
    <Tooltip title={`${sat} спутников`} arrow>
      <SatelliteIcon style={{ color: levels[sat] }} />
    </Tooltip>
  );
}

export function WatchStatus(props) {
  const { transport } = props;

  const { details } = transport;

  const { lastUpdate } = details;

  console.log({ lastUpdate, details });

  return (
    <Tooltip title={getTimeAgo(lastUpdate.timestamp)} arrow>
      <WatchIcon style={{ color: lastUpdate.color }} />
    </Tooltip>
  );
}

export function SignalStatus(props) {
  const { transport } = props;

  const { details } = transport;

  let { gsm_signal } = details;

  gsm_signal = gsm_signal ? gsm_signal : 0;

  return (
    <Tooltip title={`${gsm_signal}`} arrow>
      {gsm_signal === 0 ? (
        <SignalUnknownIcon />
      ) : (
        <SignalIcon style={{ color: gsmLevels[gsm_signal] }} />
      )}
    </Tooltip>
  );
}

export function GPSAccumulatorStatus(props) {
  const { transport } = props;

  const { details } = transport;

  let { gps_device_accumulator } = details;

  gps_device_accumulator = gps_device_accumulator ? gps_device_accumulator : 0;

  const volts = gps_device_accumulator / 1000;

  return (
    <Tooltip title={`${volts.toFixed(2)} в`} arrow>
      {volts === 0 ? (
        <ButteryUnknownIcon />
      ) : (
        <ButteryIcon style={{ color: accGPSLevels[Math.floor(volts)] }} />
      )}
    </Tooltip>
  );
}

export function TransportAccumulatorStatus(props) {
  const { transport } = props;

  const { details } = transport;

  let { transport_accumulator } = details;

  transport_accumulator = transport_accumulator ? transport_accumulator : 0;

  const volts = transport_accumulator / 1000;

  return (
    <Tooltip title={`${volts.toFixed(2)} в`} arrow>
      {volts === 0 ? (
        <PowerOffIcon />
      ) : (
        <PowerIcon style={{ color: accTransportLevels[Math.floor(volts)] }} />
      )}
    </Tooltip>
  );
}

export function NavigationStatus(props) {
  const { transport } = props;

  const { details } = transport;
  const { azimuth, engine_rpm } = details;

  return (
    <Tooltip title={`${azimuth} градусов`} arrow>
      <NavigationIcon
        style={{
          color: engine_rpm > 0 ? 'green' : 'black',

          transform: `rotateZ(${azimuth}deg)`,
          transition: '300ms ease all',
        }}
      />
    </Tooltip>
  );
}

export function EngineStatus(props) {
  const { transport } = props;
  const { details } = transport;
  const { engine_rpm } = details;

  return (
    <Tooltip title={`${engine_rpm} оборотов/мин`} arrow>
      <RotateIcon
        style={{
          color: engine_rpm > 0 ? 'green' : 'black',
          position: 'relative',
          top: 1,
        }}
      />
    </Tooltip>
  );
}
