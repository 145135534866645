import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import SplitPane from 'react-split-pane';
import TransportList from '../../components/transport-list';
import { reducer } from './reducer';
import Map from './map';
import Params from './params';
import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';
import Calendar from '../../components/calendar';
import TrackList from './track-list';

const initialState = {
  initDate: null,
  mapPoint: null,
  mapZoom: 15,
  date: null,
  transports: [],
  tracklog: [],
  currentTransport: {},
  loading: false,
};

const store = createStore(reducer, initialState);

const styles = (theme) => ({
  text: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paper: {
    padding: 20,
  },
});

const Tracks = () => {
  const [sizeLeft, setSizeLeft] = useStateWithLocalStorage({
    key: 'splitPosTransportTracksLeft',
    defaultValue: 250,
  });

  const [sizeLeftHorizontal, setSizeLeftHorizontal] = useStateWithLocalStorage({
    key: 'splitPosTransportTracksLeftHorizontal',
    defaultValue: 300,
  });

  const [mapSize, setMapSize] = useStateWithLocalStorage({
    key: 'splitPosTransportTrackMap',
    defaultValue: '30%',
  });

  return (
    <Provider store={store}>
      <Params />
      <SplitPane
        split="vertical"
        primary="first"
        minSize={250}
        style={{ height: 'calc(100% - 66px)' }}
        defaultSize={sizeLeft}
        size={sizeLeft}
        onChange={(sizeLeft) => setSizeLeft(sizeLeft)}
      >
        <SplitPane
          split="horizontal"
          primary="first"
          minSize={300}
          style={{ height: 'calc(100% - 66px)' }}
          defaultSize={sizeLeftHorizontal}
          size={sizeLeftHorizontal}
          onChange={(sizeLeftHorizontal) => setSizeLeftHorizontal(sizeLeftHorizontal)}
        >
          <TransportList />
          <Calendar />
        </SplitPane>
        <SplitPane
          split="horizontal"
          primary="first"
          minSize={100}
          style={{ height: 'calc(100% - 66px)' }}
          defaultSize={mapSize}
          size={mapSize}
          onChange={(mapSize) => setMapSize(mapSize)}
          paneStyle={{ overflowY: 'scroll' }}
        >
          <Map />
          <TrackList />
        </SplitPane>
      </SplitPane>
    </Provider>
  );
};

export default withStyles(styles)(Tracks);
