import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateAPI } from './use-api';

export const useCreateTransport = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useCreateAPI();

  const [isComplete, setComplete] = useState(false);

  useEffect(() => {
    if (data && isSuccess) {
      const { data: response } = data;

      if (response.success) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }
  }, [dispatch, data, isSuccess]);

  const onCreateTransport = useCallback(
    (props) => {
      mutate({
        transport: props,
      });
    },
    [mutate],
  );

  return { onCreateTransport, isLoading, isComplete, data, setComplete };
};
