import { useQuery } from 'react-query';
import { eventsAPI } from '../../api-controller/events-controller';
import { NotificationManager } from 'react-notifications';

export const useAPI = ({ startDate, endDate, transportId, page, rowsPerPage, order, orderBy }) => {
  const {
    isIdle,
    isLoading,
    error,
    refetch,
    data: events,
  } = useQuery(
    ['events', startDate, endDate, transportId, page, rowsPerPage, order, orderBy],
    () => eventsAPI.all({ startDate, endDate, transportId, page, rowsPerPage, order, orderBy }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, events };
};
