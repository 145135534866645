import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { routeAPI } from '../api-controller/route-controller';

export const useGetAllAPI = () => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(['routes'], () => routeAPI.all(), {
    keepPreviousData: true,
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  return { isLoading, isIdle, error, refetch, data };
};

export const useUpdateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id, body }) => routeAPI.update({ id, body }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Изменения сохраненены!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка редактирования маршрута!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ body }) => routeAPI.create({ body }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Маршрут создан!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания маршрута!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useDeleteAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id }) => routeAPI.delete({ id }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Маршрут удален!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка удаления маршрута!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
