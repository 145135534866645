import axios from 'axios';

export const getGeozonesEndpoint = () => {
  return process.env.REACT_APP_ENDPOINT + '/api/v1/geozone';
};

export const geozoneAPI = {
  all: async () => {
    const { data } = await axios.get(getGeozonesEndpoint());
    return data;
  },

  create: async ({ body }) => {
    const { data } = await axios.post(getGeozonesEndpoint(), body);
    return data;
  },
  delete: async ({ id }) => {
    const { data } = await axios.delete(getGeozonesEndpoint() + '/' + id);
    return data;
  },

  update: async ({ id, body }) => {
    const { data } = await axios.put(getGeozonesEndpoint({ id }) + '/' + id, body);
    return data;
  },
};
