import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateAPI } from '../../api/use-geozone-api';

export const useUpdateGeozone = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useUpdateAPI();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch({
        type: 'UPDATE_GEOZONE',
        value: {
          id: data.response._id,
          geozone: data.response,
        },
      });
    }
  }, [data, isSuccess]);

  const onCreateGeozone = useCallback(
    (e) => {
      e.features.forEach((poly) => {
        mutate({
          id: poly.id,
          body: { geometry: poly.geometry, name: poly.properties.name },
        });
      });
    },
    [mutate],
  );

  return onCreateGeozone;
};
