import axios from 'axios';

export const endpoint = process.env.REACT_APP_ENDPOINT + '/api/v1/event';

export const eventsAPI = {
  all: async ({ startDate, endDate, transportId, page, rowsPerPage, order, orderBy }) => {
    const { data } = await axios.get(endpoint, {
      params: {
        page,
        pageSize: rowsPerPage,
        startDate,
        endDate,
        transportId,
        order,
        orderBy,
      },
    });
    return data;
  },
};
