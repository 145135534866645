import React from 'react';
import TextField from '@material-ui/core/TextField';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogFormContent = (props) => {
  const { formik, classes } = props;
  return (
    <DialogContent dividers>
      <form onSubmit={formik.handleSubmit} className={classes.root} noValidate autoComplete="off">
        <TextField
          id="outlined-basic"
          name="imei"
          label="imei"
          variant="outlined"
          error={formik.touched.imei && formik.errors.imei}
          helperText={formik.touched.imei && formik.errors.imei}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.imei}
        />
        <TextField
          id="outlined-basic"
          label="Моб. Телефон"
          variant="outlined"
          name="phone"
          error={formik.touched.phone && formik.errors.phone}
          helperText={formik.touched.phone && formik.errors.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
        />
        <TextField
          id="outlined-basic"
          label="Бренд"
          variant="outlined"
          name="brand"
          error={formik.touched.brand && formik.errors.brand}
          helperText={formik.touched.brand && formik.errors.brand}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.brand}
        />
        <TextField
          id="outlined-basic"
          label="Модель"
          variant="outlined"
          name="model"
          error={formik.touched.model && formik.errors.model}
          helperText={formik.touched.model && formik.errors.model}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.model}
        />
        <TextField
          id="outlined-basic"
          label="Гос. номер"
          variant="outlined"
          name="number"
          error={formik.touched.number && formik.errors.number}
          helperText={formik.touched.number && formik.errors.number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.number}
        />
      </form>
    </DialogContent>
  );
};
