import React, { useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import Chip from '@material-ui/core/Chip';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectColors from '../select-color';
import { useUpdateAPI } from '../../api/use-geozone-api';

const styles = (theme) => ({
  textField: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function valuetext(value) {
  return `${value} м`;
}

function EditForm(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const { currentGeozone } = useSelector((state) => state);

  const { mutate, isLoading, isSuccess, data } = useUpdateAPI();

  console.log({ currentGeozone });

  useEffect(() => {
    if (isSuccess && data) {
      dispatch({
        type: 'UPDATE_GEOZONE',
        value: {
          id: data.response._id,
          geozone: data.response,
        },
      });
    }
  }, [data, isSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: currentGeozone.name,
        id: currentGeozone._id,
        color: currentGeozone.color || '#ff9800',
        allowedParkingDuration: currentGeozone.allowedParkingDuration || 5,
        allowMaxSpeed: currentGeozone.allowMaxSpeed || 60,
        groups: currentGeozone.groups,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(128, 'Слишком длинное имя').required('Введите имя геозоны'),
      })}
      onSubmit={async (values) => {
        mutate({ id: currentGeozone._id, body: values });
      }}
      render={({
        values,
        touched,
        errors,
        handleSubmit,
        handleBlur,
        handleChange,
        setValues,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: 16 }}
            >
              <Typography>Настройка геозоны</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="id"
                    name="id"
                    disabled
                    value={values.id || ''}
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    error={!!errors.name}
                    helperText={errors.name}
                    type="text"
                    value={values.name || ''}
                    onChange={handleChange}
                    name="name"
                    label="Имя геозоны"
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                  />

                  <InputLabel htmlFor="age-customized-select">Цвет геозоны:</InputLabel>
                  <SelectColors errors={errors} values={values} handleChange={handleChange} />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor="age-customized-select">
                    Допустимое время стоянки {values.allowedParkingDuration} мин
                  </InputLabel>
                  <Slider
                    step={1}
                    min={2}
                    max={60}
                    name="departure"
                    onChange={(e, val) =>
                      setValues({
                        ...values,
                        allowedParkingDuration: val,
                      })
                    }
                    value={values.allowedParkingDuration}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="age-customized-select">
                    Максимальная скорость {values.allowMaxSpeed} км/ч
                  </InputLabel>
                  <Slider
                    step={1}
                    min={1}
                    max={140}
                    name="departure"
                    onChange={(e, val) =>
                      setValues({
                        ...values,
                        allowMaxSpeed: val,
                      })
                    }
                    value={values.allowMaxSpeed}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                  />
                </Grid>

                <Grid item xs={12}>
                  {values.groups &&
                    values.groups.map((group) => (
                      <Chip
                        key={group._id}
                        label={group.name}
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: 1,
                          fontSize: 10,
                        }}
                      />
                    ))}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={values.id ? false : true}
                  >
                    {isLoading ? (
                      <CircularProgress
                        className={classes.facebook}
                        variant="indeterminate"
                        disableShrink
                        size={24}
                        thickness={4}
                        style={{ marginRight: 10 }}
                      />
                    ) : null}
                    Обновить
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
}

export default withStyles(styles)(EditForm);
