import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import {
  SateliteStatus,
  WatchStatus,
  SignalStatus,
  GPSAccumulatorStatus,
  TransportAccumulatorStatus,
  NavigationStatus,
  EngineStatus,
} from '../online-locator-controls';

import TransportIcon from '@material-ui/icons/LocalShipping';

export default function Transport(props) {
  const { transport, currentTransport, dispatch } = props;

  return (
    <React.Fragment>
      <ListItem
        button
        selected={currentTransport?._id === transport._id}
        key={transport._id}
        onClick={() => {
          dispatch({
            type: 'SET_CURRENT_TRANSPORT',
            value: transport,
          });
        }}
      >
        <ListItemAvatar>
          <Avatar style={{ background: transport.color }}>
            <TransportIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${transport.brand} ${transport.model}`}
          secondary={
            <React.Fragment>
              {transport.imei} {transport.number}
              <br />
              {transport.details && <SateliteStatus transport={transport} />}
              {transport.details && <SignalStatus transport={transport} />}
              {transport.details && <GPSAccumulatorStatus transport={transport} />}
              {transport.details && <TransportAccumulatorStatus transport={transport} />}
              {transport.details && <WatchStatus transport={transport} />}
              {transport.details && <EngineStatus transport={transport} />}
              {transport.details && <NavigationStatus transport={transport} />}
            </React.Fragment>
          }
        />
      </ListItem>
    </React.Fragment>
  );
}
