export const reducer = (state, action) => {
  let index, newState;

  switch (action.type) {
    case 'setMapPoint':
      return {
        ...state,
        mapPoint: action.value.mapPoint,
        mapZoom: action.value.mapZoom,
      };

    case 'setDate':
      return {
        ...state,
        date: action.value,
      };

    case 'SET_INIT_DATE':
      return {
        ...state,
        initDate: action.value,
      };

    case 'SET_TRACK_LOG':
      return {
        ...state,
        tracklog: action.value,
      };

    case 'ADD_CHUNK_TO_TRACK_LOG':
      return {
        ...state,
        tracklog: [...state.tracklog, ...action.value],
      };

    case 'setLoading':
      return {
        ...state,
        loading: action.value,
      };

    case 'setPanelSize':
      return {
        ...state,
        panelSize: action.value,
      };
    case 'loadTransports':
      return {
        ...state,
        transports: action.value,
      };

    case 'SET_CURRENT_TRANSPORT':
      return {
        ...state,
        currentTransport: action.value,
      };

    default:
      return state;
  }
};
