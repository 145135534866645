import React, { useState } from 'react';

import SplitPane from 'react-split-pane';

import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';
import GeozoneList from '../../components/geozone-list';
import EditForm from '../../components/geozone-edit-form';
import Map from '../../components/map-geozone-editor';
import './index.css';

const SplitPanels = (props) => {
  const [size, setSize] = useStateWithLocalStorage({
    key: 'splitPosGeoZonesVerticalEditor',
    defaultValue: '30%',
  });

  const [hSize, setHSize] = useStateWithLocalStorage({
    key: 'splitPosGeoZonesHorizontalEditor',
    defaultValue: 100,
  });

  const [proxySize, setProxySize] = useState(size);

  return (
    <React.Fragment>
      <SplitPane style={{ height: 'calc(100% - 66px)' }} split="vertical" minSize={0}>
        <div />
        <SplitPane
          split="vertical"
          primary="first"
          defaultSize={size}
          size={size}
          onChange={(size) => setSize(size)}
          onDragFinished={() => setProxySize(size)}
          minSize={100}
        >
          <SplitPane
            split="horizontal"
            primary="first"
            minSize={100}
            paneStyle={{ overflowY: 'auto' }}
            defaultSize={hSize}
            size={hSize}
            onChange={(size) => setHSize(size)}
          >
            <GeozoneList />
            <EditForm />
          </SplitPane>
          <Map size={proxySize} />
        </SplitPane>
      </SplitPane>
    </React.Fragment>
  );
};

export default SplitPanels;
