import { useQuery } from 'react-query';
import { trackLogStatsAPI } from '../../api-controller/tracklog-stats-controller';
import { NotificationManager } from 'react-notifications';

export const useGetTrackYears = () => {
  const {
    isLoading,
    error,
    data,
  } = useQuery(['yaars'], () => trackLogStatsAPI.getYears(), {
    keepPreviousData: true,
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  return { isLoading, error, data };
};

export const useAPI = ({ startDate, endDate }) => {
  const {
    isIdle,
    isLoading,
    error,
    refetch,
    data: stats,
  } = useQuery(['stats', startDate, endDate], () => trackLogStatsAPI.all({ startDate, endDate }), {
    keepPreviousData: true,
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  return { isLoading, isIdle, error, refetch, stats };
};

export const useBarAPI = ({ date, transportId }) => {
  const {
    isIdle,
    isLoading,
    error,
    refetch,
    data: stats,
  } = useQuery(
    ['statsByMonth', date, transportId],
    () => trackLogStatsAPI.daysByMonth({ date, transportId }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, stats };
};
