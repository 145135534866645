import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Tracks from '../pages/tracks';
import Geozones from '../pages/geozones';
import Routes from '../pages/routes';
import Locator from '../pages/locator';
import Transports from '../pages/transports';
import Gas from '../pages/gas';
import Events from '../pages/events';
import Stats from '../pages/stats';
import Logout from '../pages/logout';
import Chatbot from '../pages/chatbot';
import IndexPage from '../pages/index-page/index';
import { Loader } from '../components/loader';
import { setKeyCloak } from '../data/network/http';
import { Bar } from '../components/app-bar';

export const App = () => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <Loader text='Идет загрузка данных...' />;
  }

  if (keycloak.token != null) {
    setKeyCloak(keycloak);
  } else {
    keycloak.login();
  }

  return (
    <>
      <Router>
        <Bar />
        <Switch>
          <Route path='/index'>
            <IndexPage />
          </Route>
          <Route path='/transports'>
            <Transports />
          </Route>
          <Route path='/tracks/:transportId?/:date?'>
            <Tracks />
          </Route>
          <Route path='/geozones'>
            <Geozones />
          </Route>
          <Route path='/routes'>
            <Routes />
          </Route>
          <Route path='/locator/:transportId?'>
            <Locator />
          </Route>
          <Route path='/gas'>
            <Gas />
          </Route>
          <Route path='/stats'>
            <Stats />
          </Route>
          <Route path='/events'>
            <Events />
          </Route>
          <Route path='/chatbot'>
            <Chatbot />
          </Route>
          <Route path='/logout'>
            <Logout />
          </Route>
          <Redirect exact from="/" to='index' />
        </Switch>
      </Router>
    </>
  );
};
