import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { sprintGPSDatetime } from '../../utils/datetime';
import { trackLightColor } from '../../utils/speed';

import { TableCell, TableRow } from '@material-ui/core';

const HeadTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function CustomTableRow({
  index,
  averageSpeed,
  firstPointOfChunk,
  lastPointOfChunk,
  distance,
  duration,
  chunk,
}) {
  const dispatch = useDispatch();

  const onClick = useCallback(
    (firstPointOfChunk) => {
      dispatch({
        type: 'setMapPoint',
        value: {
          mapPoint: {
            lat: firstPointOfChunk.location.coordinates[1],
            lng: firstPointOfChunk.location.coordinates[0],
          },
          mapZoom: 20,
        },
      });
    },
    [firstPointOfChunk],
  );

  return (
    <TableRow
      key={index}
      style={{
        backgroundColor: trackLightColor(averageSpeed),
        color: 'white',
        cursor: 'pointer',
      }}
      onClick={() => onClick(firstPointOfChunk)}
    >
      <HeadTableCell>{firstPointOfChunk.speed === 0 ? 'Остановка' : 'Движение'}</HeadTableCell>
      <HeadTableCell>{sprintGPSDatetime(firstPointOfChunk.gps_timestamp)}</HeadTableCell>
      <HeadTableCell>{sprintGPSDatetime(lastPointOfChunk.gps_timestamp)}</HeadTableCell>
      <HeadTableCell>{`${duration.print}`}</HeadTableCell>

      <HeadTableCell>{averageSpeed.toFixed(2)}</HeadTableCell>
      <HeadTableCell>{distance.toFixed(2)}</HeadTableCell>
      <HeadTableCell>0</HeadTableCell>
      <HeadTableCell>{chunk.geoCode}</HeadTableCell>
    </TableRow>
  );
}
