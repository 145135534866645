import _ from 'lodash';
import React, { useEffect } from 'react';
import moment from 'moment';
import { List, ListItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ListItemText from '@material-ui/core/ListItemText';

import { useAPI } from './use-api';
import Transport from './transport';
import LoaderProgress from '../../components/circular-progress-center';
import { useParams } from 'react-router-dom';

const LoadingContainer = () => (<LoaderProgress justify='center' alignItems='center' style={{ marginTop: 20 }} />);

const transportListMapping = ({ onlineMarkers, transports }) => {
  if (!onlineMarkers || onlineMarkers.length === 0) return transports;

  const getLastUpdate = (transId) => {
    const transport = onlineMarkers.find((online) => online.transport_id === transId);

    const min = moment(new Date()).diff(moment(transport.gps_timestamp).local()) / 1000 / 60;

    switch (true) {
      case min > 60:
        return { color: 'red', min, timestamp: transport.gps_timestamp };
      case min < 10:
        return { color: 'green', min, timestamp: transport.gps_timestamp };
      case min < 20:
        return { color: 'yellow', min, timestamp: transport.gps_timestamp };
      default:
        return { color: 'orange', min, timestamp: transport.gps_timestamp };
    }
  };

  const getDetails = (transId) => {
    const transport = onlineMarkers.find((online) => online.transport_id === transId);

    return {
      location: transport.location,
      sat: transport.sat,
      azimuth: _.get(transport, 'Azimuth', 0),
      engine_rpm: _.get(transport, 'wparams.engine_rpm', 0),
      gsm_signal: _.get(transport, 'bparams.gsm_signal', 0),
      gps_device_accumulator: _.get(transport, 'wparams.gps_device_accumulator', 0),
      transport_accumulator: _.get(transport, 'wparams.transport_accumulator', 0),
    };
  };

  return transports
    .filter((trans) => onlineMarkers.find((online) => online.transport_id === trans._id))
    .map((online) => ({
      ...online, color: getLastUpdate(online._id).color, details: {
        ...getDetails(online._id), lastUpdate: getLastUpdate(online._id),
      },
    }));
};

const TransportList = (props) => {
  const dispatch = useDispatch();
  const { transportId } = useParams();

  const { currentTransport, onlineMarkers } = useSelector((state) => state);

  const { transports, isLoading, error } = useAPI();

  useEffect(() => {
    if (transportId && transports?.length > 0 && onlineMarkers?.length > 0) {
      const foundTransport = transportListMapping({
        transports,
        onlineMarkers,
      }).find(({ _id }) => _id === transportId);
      if (foundTransport?._id === transportId) {
        dispatch({
          type: 'SET_CURRENT_TRANSPORT', value: foundTransport,
        });
      }
    }
  }, [transportId, transports, onlineMarkers]);

  return (<div style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
    {isLoading ? <LoadingContainer /> : null}
    {error && (<div style={{ color: 'red', padding: 16, textAlign: 'center' }}>
      Ошибка загрузки транспортных стредств
    </div>)}
    <List component='nav' style={{ width: '100vh' }}>
      {transports && transports.length === 0 && isLoading === false ? (<ListItem>
        <ListItemText secondary={_.get(props, 'emptyMessage', 'Нет транспортных средств')} />
      </ListItem>) : null}
      {transports && transportListMapping({ transports, onlineMarkers }).map((transport) => (<Transport
        key={transport._id}
        transport={transport}
        currentTransport={currentTransport}
        dispatch={dispatch}
      />))}
    </List>
  </div>);
};

export default TransportList;
