import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useMapbox } from './use-mapbox';
import { useSocket } from './use-socket';
import { VECTOR, SATELLITE } from '../../core/consts';

import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';
import 'mapbox-gl/dist/mapbox-gl.css';

export const Map = ({ size }) => {
  const { onlineMarkers, currentTransport } = useSelector((state) => state);

  const mapContainer = useRef(null);

  useSocket();

  const [zoom, setZoom] = useStateWithLocalStorage({
    key: 'map.zoom.locator',
    defaultValue: 11,
  });

  const [center, setCenter] = useStateWithLocalStorage({
    key: 'map.center.locator',
    defaultValue: [31.937222613289606, 49.39850281447136],
  });

  const [typeMap, setTypeMap] = useStateWithLocalStorage({
    key: 'map.style.locator',
    defaultValue: VECTOR,
  });

  const [map, styleLoaded, setStyleLoaded, loaded, setLoaded] = useMapbox({
    zoom,
    center,
    mapContainer,
    typeMap,
  });

  function changeStyleMap(style) {
    if (typeMap !== style) {
      setTypeMap(style);
      setLoaded(false);
      setStyleLoaded(false);
    }
  }

  useEffect(() => {
    map.current.resize();
  }, [size, map]);

  useEffect(() => {
    if (!map) return;

    map.current.on('zoomend', () => {
      setZoom(map.current.getZoom());
      setCenter(map.current.getCenter());
    });
  }, [map, setZoom, setCenter]);

  useEffect(() => {
    if (!styleLoaded || !loaded || !map.current) return;

    const size = 100;
    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function() {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // Call once before every frame where the icon will be used.
      render: function() {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
        context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
        context.fill();

        // Draw the inner circle.
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        // Update this image's data with data from the canvas.
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        map.current.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };

    map.current.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

    if (map.current.getSource('dotPoint')) {
      map.current.getSource('dotPoint').setData({
        type: 'FeatureCollection',
        features: onlineMarkers.map((marker) => ({
          type: 'Feature',
          geometry: marker.location,
        })),
      });
    } else {
      map.current.addSource('dotPoint', {
        type: 'geojson',
        cluster: false,

        data: {
          type: 'FeatureCollection',
          features: onlineMarkers.map((marker) => ({
            type: 'Feature',
            geometry: marker.location,
          })),
        },
      });
    }

    if (map.current.getLayer('layer-with-pulsing-dot')) {
      map.current.removeLayer('layer-with-pulsing-dot');
    }
    map.current.addLayer({
      id: 'layer-with-pulsing-dot',
      type: 'symbol',
      source: 'dotPoint',
      layout: {
        'icon-image': 'pulsing-dot',
      },
    });
  }, [loaded, styleLoaded, typeMap]);

  useLayoutEffect(() => {
    if (!styleLoaded || !loaded || !map.current) return;

    if (!currentTransport || !currentTransport?.details) return;

    const currentActiveMarker = onlineMarkers.find(
      (marker) => marker.transport_id === currentTransport._id,
    );

    if (
      currentTransport.details.location.coordinates[0] !==
      currentActiveMarker.location.coordinates[0] &&
      currentTransport.details.location.coordinates[1] !==
      currentActiveMarker.location.coordinates[1]
    ) {
      setCenter(currentActiveMarker.location.coordinates);
      map.current.panTo(currentActiveMarker.location.coordinates);
    }

    const data = {
      type: 'FeatureCollection',
      features: onlineMarkers.map((marker) => ({
        type: 'Feature',
        geometry: marker.location,
      })),
    };

    if (map.current.getSource('dotPoint')) {
      map.current.getSource('dotPoint').setData(data);
    } else {
      map.current.addSource('dotPoint', {
        type: 'geojson',
        cluster: false,
        data,
      });
    }
  }, [onlineMarkers, styleLoaded, typeMap]);

  useLayoutEffect(() => {
    if (currentTransport?.details?.location) {
      setCenter(currentTransport.details.location.coordinates);
      if (map.current && currentTransport?.details?.location?.coordinates) {
        map.current.panTo(currentTransport.details.location.coordinates);
      }
    }
  }, [map, currentTransport]);

  return (
    <div>
      <div
        ref={mapContainer}
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
      />
      <Button
        variant={typeMap === SATELLITE ? 'contained' : 'outlined'}
        size='small'
        color='primary'
        style={{ position: 'absolute', top: 16, left: 16, cursor: 'pointer' }}
        onClick={() => changeStyleMap(SATELLITE)}
      >
        Спутник
      </Button>
      <Button
        variant={typeMap === VECTOR ? 'contained' : 'outlined'}
        size='small'
        color='primary'
        style={{ position: 'absolute', top: 16, left: 110, cursor: 'pointer' }}
        onClick={() => changeStyleMap(VECTOR)}
      >
        Вектор
      </Button>
    </div>
  );
};

export default Map;
