export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELDS':
      return { ...state, fields: action.value };

    case 'SET_CURRENT_FIELD_ID':
      return { ...state, currentFieldId: action.value };

    case 'SET_MAP_BOX_MODE':
      return { ...state, mapBoxMode: action.value };

    default:
      return state;
  }
};
