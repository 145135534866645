import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateAPI } from '../../api/use-route-api';

export const useCreateRoute = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useCreateAPI();

  useEffect(() => {
    if (data && isSuccess) {
      dispatch({
        type: 'CREATE_ROUTE',
        value: data.response,
      });
    }
  }, [dispatch, data, isSuccess]);

  const onCreateRoute = useCallback(
    (e) => {
      e.features.forEach((poly) => {
        mutate({
          body: { geometry: poly.geometry, name: 'Созданный маршрут' },
        });
      });
    },
    [mutate],
  );

  return onCreateRoute;
};
