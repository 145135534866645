import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useKeycloak } from '@react-keycloak/web';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EventIcon from '@material-ui/icons/EventNote';
import MenuIcon from '@material-ui/icons/Menu';
import TrackIcon from '@material-ui/icons/CallSplit';
import ChatIcon from '@material-ui/icons/Chat';
import GeozoneIcon from '@material-ui/icons/Layers';
import RouteIcon from '@material-ui/icons/Timeline';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import CommuteIcon from '@material-ui/icons/Commute';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import NavigationIcon from '@material-ui/icons/Navigation';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: '2px solid black',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

const menu = [
  {
    text: 'Транспортные средства',
    icon: <CommuteIcon />,
    link: '/transports',
  },
  {
    text: 'Геозоны',
    icon: <GeozoneIcon />,
    link: '/geozones',
  },
  {
    text: 'Маршруты',
    icon: <RouteIcon />,
    link: '/routes',
  },
  {
    text: 'Треки',
    icon: <TrackIcon />,
    link: '/tracks',
  },

  {
    text: 'Топливо',
    icon: <LocalGasStationIcon />,
    link: '/gas',
  },
  {
    text: 'Статистика',
    icon: <EqualizerIcon />,
    link: '/stats',
  },
  {
    text: 'События',
    icon: <EventIcon />,
    link: '/events',
  },
  {
    text: 'Локатор',
    icon: <NavigationIcon />,
    link: '/locator',
  },
  {
    text: 'Чат бот',
    icon: <ChatIcon />,
    link: '/chatbot',
  },
  {
    text: 'Выход',
    icon: <LogoutIcon />,
    link: '/logout',
  },
];

export function Bar() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [userName, setUserName] = useState('');

  const { keycloak } = useKeycloak();

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      setUserName(userInfo.name);
    });
  }, []);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menu.map(({ text, icon, link }, index) => (
          <ListItem
            disabled={location.pathname === link}
            button
            key={text}
            onClick={() => history.push(link)}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" style={{ backgroundColor: '#43a047' }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" className={classes.title} style={{ color: 'white' }}>
            <Link to={'/index'} style={{color: 'white', textDecoration: 'none'}}>AGRO.UNIT <Hidden only="xs">(Транспортный контроль)</Hidden></Link>
          </Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Tooltip title={userName} aria-label="add">
              <AccountCircle style={{ color: 'white' }} />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
    </div>
  );
}
