import { useQuery, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { geozoneAPI } from '../api-controller/geozone-controller';

export const useGetAllAPI = () => {
  const { isIdle, isLoading, error, refetch, data } = useQuery(
    ['geozones'],
    () => geozoneAPI.all(),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, isIdle, error, refetch, data };
};

export const useUpdateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id, body }) => geozoneAPI.update({ id, body }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Изменения сохраненены!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка редактирования геозоны!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useCreateAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ body }) => geozoneAPI.create({ body }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Геозона создана!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка создания геозоны!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};

export const useDeleteAPI = () => {
  const { mutate, isLoading, isSuccess, error, data } = useMutation(
    ({ id }) => geozoneAPI.delete({ id }),
    {
      onSuccess: () => {
        NotificationManager.success('', 'Геозона удалена!');
      },
      onError: (error) => {
        NotificationManager.error(error.message, 'Ошибка удаления геозоны!');
      },
    },
  );
  return { isLoading, isSuccess, error, data, mutate };
};
