import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTableRow from './table-row';
import { useAPI } from './use-api';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { sprintDuration } from '../../utils/datetime';

import LoaderProgress from '../../components/circular-progress-center';

const LoadingContainer = (props) => <LoaderProgress justify="center" alignItems="center" />;

const HeadTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function TrackList() {
  const dispatch = useDispatch();
  const { date, tracklog, currentTransport } = useSelector((state) => state);

  const [message, setMessage] = useState('');

  const [trackLogParams, setTrackLogParams] = useState({ date: null, transport_id: null });

  const { trackLogs, isLoading, error } = useAPI(trackLogParams);

  useEffect(() => {
    setTrackLogParams({ date, transport_id: currentTransport?._id });
  }, [date, currentTransport]);

  useEffect(() => {
    dispatch({
      type: 'SET_TRACK_LOG',
      value: trackLogs,
    });
  }, [trackLogs, dispatch]);

  useEffect(() => {
    if (error) setMessage(error);
  }, [error]);

  return (
    <Table>
      <React.Fragment>
        <TableHead>
          <TableRow>
            <HeadTableCell>Событие</HeadTableCell>
            <HeadTableCell>Начало</HeadTableCell>
            <HeadTableCell>Конец</HeadTableCell>
            <HeadTableCell>Время</HeadTableCell>
            <HeadTableCell>Ср.скорость (км/ч)</HeadTableCell>

            <HeadTableCell>Дистанция (км)</HeadTableCell>
            <HeadTableCell>Топливо (л)</HeadTableCell>
            <HeadTableCell>Средняя точка</HeadTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <HeadTableCell colSpan={8}>
                <LoadingContainer />
              </HeadTableCell>
            </TableRow>
          ) : tracklog ? (
            tracklog.map((chunk, index) => {
              const { chunk: currentChunk, averageSpeed, distance } = chunk;

              const firstPointOfChunk = currentChunk[0];
              const lastPointOfChunk = currentChunk[currentChunk.length - 1];

              const duration = sprintDuration(
                firstPointOfChunk.gps_timestamp,
                lastPointOfChunk.gps_timestamp,
              );

              return (
                <CustomTableRow
                  index={index}
                  averageSpeed={averageSpeed}
                  firstPointOfChunk={firstPointOfChunk}
                  lastPointOfChunk={lastPointOfChunk}
                  distance={distance}
                  duration={duration}
                  chunk={chunk}
                />
              );
            })
          ) : (
            <TableRow>
              <HeadTableCell colSpan={8}>Нет данных {message}</HeadTableCell>
            </TableRow>
          )}
        </TableBody>
      </React.Fragment>
    </Table>
  );
}
