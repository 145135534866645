import { useQuery } from 'react-query';
import { transportAPI } from '../../api-controller/transport-controller';
import { NotificationManager } from 'react-notifications';

export const useAPI = () => {
  const {
    isLoading,
    error,
    refetch,
    data: transports,
  } = useQuery(['transports'], () => transportAPI.all(), {
    keepPreviousData: true,
    onError: (error) => {
      NotificationManager.error(error.message);
    },
  });

  return { isLoading, error, refetch, transports };
};
