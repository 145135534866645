import React, { useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { VECTOR, SATELLITE } from '../../core/consts';

import { useMapbox } from './use-mapbox';
import { useMapboxDraw } from './use-mapbox-draw';
import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const GeozoneEditor = ({ size }) => {
  const [zoom, setZoom] = useStateWithLocalStorage({
    key: 'map.zoom.route.editor',
    defaultValue: 11,
  });

  const [center, setCenter] = useStateWithLocalStorage({
    key: 'map.center.route.editor',
    defaultValue: [31.937222613289606, 49.39850281447136],
  });

  const [typeMap, setTypeMap] = useStateWithLocalStorage({
    key: 'map.style.route.editor',
    defaultValue: VECTOR,
  });

  const mapContainer = useRef(null);

  const { routes, currentRoute } = useSelector((state) => state);

  const [map, styleLoaded, setStyleLoaded, loaded, setLoaded] = useMapbox({
    zoom,
    center,
    mapContainer,
    typeMap,
  });

  const draw = useMapboxDraw({ map, typeMap });

  function changeStyleMap(style) {
    if (typeMap !== style) {
      setTypeMap(style);
      setLoaded(false);
      setStyleLoaded(false);
    }
  }

  useEffect(() => {
    map.current.resize();
  }, [size]);

  useEffect(() => {
    if (!styleLoaded || !loaded || !map.current) return;
    if (map?.current?.getSource('share_label') || map?.current?.getLayer('share_label')) return;

    map.current.addSource('share_label', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }, [map, loaded, styleLoaded, typeMap]);

  useEffect(() => {
    if (!draw) return;
    if (!styleLoaded || !loaded || !map.current) return;

    draw.deleteAll();

    routes
      .map((route) => {
        return {
          type: 'Feature',
          geometry: route.geometry,
          id: route._id,

          properties: {
            name: route.name,
            class_id: route.color,
          },
        };
      })
      .forEach((geozone) => draw.add(geozone));

    const routeLabels = [];

    const all = draw.getAll();
    if (all && all.features) {
      all.features.forEach(function (feature) {
        const firstPoint = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: feature.geometry.coordinates[0],
          },
          properties: {
            label: `Начало маршрута "${feature.properties.name}"`,
          },
        };

        routeLabels.push(firstPoint);

        const lastPoint = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: feature.geometry.coordinates[feature.geometry.coordinates.length - 1],
          },
          properties: {
            label: `Конец маршрута "${feature.properties.name}"`,
          },
        };

        routeLabels.push(lastPoint);
      });
    }

    if (map.current.getLayer('share_label')) map.current.removeLayer('share_label');

    map.current.addLayer({
      id: 'share_label',
      source: 'share_label',
      type: 'symbol',
      paint: {
        'text-color': typeMap === VECTOR ? 'black' : 'white',
      },
      layout: {
        'text-field': '{label}',
        'text-size': 12,
        'text-offset': [0, -2],
        //'icon-image': 'kitten',
        'icon-offset': [0, 20],
      },
    });

    if (map.current.getLayer('arrowId')) map.current.removeLayer('arrowId');

    map.current.addLayer({
      id: 'arrowId',
      type: 'symbol',
      source: 'share_label',
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 1,
        'icon-allow-overlap': true,
        // 'icon-ignore-placement': true,
        'icon-image': 'harbor-15',
        visibility: 'visible',
      },
    });

    // measurements layer

    map.current.getSource('share_label').setData({
      type: 'FeatureCollection',
      features: routeLabels,
    });
  }, [map, draw, routes, currentRoute, loaded, styleLoaded, typeMap]);

  useEffect(() => {
    if (!map) return;

    map.current.on('zoomend', () => {
      setZoom(map.current.getZoom());
      setCenter(map.current.getCenter());
    });
  }, [map, setZoom, setCenter]);

  useEffect(() => {
    if (!currentRoute?.center) return;

    console.log({ center: [currentRoute.center.lng, currentRoute.center.lat] });
    map.current.setCenter([currentRoute.center.lng, currentRoute.center.lat]);
  }, [currentRoute]);

  return (
    <div>
      <div
        ref={mapContainer}
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
      />
      <Button
        variant={typeMap === SATELLITE ? 'contained' : 'outlined'}
        size="small"
        color="primary"
        style={{ position: 'absolute', top: 16, left: 16, cursor: 'pointer' }}
        onClick={() => changeStyleMap(SATELLITE)}
      >
        Спутник
      </Button>
      <Button
        variant={typeMap === VECTOR ? 'contained' : 'outlined'}
        size="small"
        color="primary"
        style={{ position: 'absolute', top: 16, left: 110, cursor: 'pointer' }}
        onClick={() => changeStyleMap(VECTOR)}
      >
        Вектор
      </Button>
    </div>
  );
};

export default GeozoneEditor;
