import React, { useRef, useEffect } from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import RouteIcon from '@material-ui/icons/NearMe';
import _ from 'lodash';

export default function Route(props) {
  const { route, currentRoute, dispatch } = props;

  const [open, setOpen] = React.useState(false);

  const ref = useRef();

  useEffect(() => {
    if (_.get(currentRoute, '_id', null) === route._id) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentRoute]);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <React.Fragment>
      <ListItem
        ref={ref}
        button
        selected={_.get(currentRoute, '_id', null) === route._id ? true : false}
        key={route._id}
        onClick={() => {
          handleClick();
          dispatch({
            type: 'SET_MAP_POINT',
            value: { mapPoint: route.center, mapZoom: 15 },
          });

          dispatch({
            type: 'SET_CURRENT_ROUTE',
            value: route,
          });
        }}
      >
        <ListItemAvatar>
          <Avatar style={{ background: route.color }}>
            <RouteIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${route.name}`} secondary={`${route.distance.toFixed(2)} км`} />
      </ListItem>
    </React.Fragment>
  );
}
