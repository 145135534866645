import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Index from './index-page';
import { reducer } from './reducer';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { CardHeader } from '@material-ui/core';
import EventsTable from '../events/events';

import moment from 'moment';
import Grid from '@material-ui/core/Grid';

const initialState = {
  mapZoom: 15,
  geozones: [],
  routes: [],
  onlineMarkers: [],
  transports: [],
  currentTransport: {},
  loading: false,
  initTransports: [],
  startDate: moment(new Date()).startOf('day').utc().toISOString(),
  endDate: moment(new Date()).endOf('day').utc().toISOString(),

};

const store = createStore(reducer, initialState);

const IndexPage = () => {
  return (
    <Provider store={store}>
      <div style={{ margin: 16 }}>
        <Grid container direction='row' justifyContent='center' spacing={2}>
          <Grid item xs={6}>
            <Card>
              <CardHeader title={'Транспортные средства'} subheader={'Список состояний'} />
              <CardContent>
                <Index />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              {/*<CardHeader title={'События'} subheader={'Список событий'} />*/}
              <CardContent>
                <EventsTable />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

    </Provider>
  );
};

export default IndexPage;
