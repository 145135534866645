import React, { useRef, useEffect, useState } from 'react';
import turf from 'turf';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useMapbox } from './use-mapbox';
import { useMapboxDraw } from './use-mapbox-draw';
import { useStateWithLocalStorage } from '../../hooks/use-state-with-storage';
import { VECTOR, SATELLITE } from '../../core/consts';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const GeozoneEditor = ({ size }) => {
  const [zoom, setZoom] = useStateWithLocalStorage({
    key: 'map.zoom.geozone.editor',
    defaultValue: 11,
  });

  const [center, setCenter] = useStateWithLocalStorage({
    key: 'map.center.geozone.editor',
    defaultValue: [31.937222613289606, 49.39850281447136],
  });

  const [typeMap, setTypeMap] = useStateWithLocalStorage({
    key: 'map.style.locator',
    defaultValue: VECTOR,
  });

  const mapContainer = useRef(null);

  const { geozones, currentGeozone } = useSelector((state) => state);

  const [map, styleLoaded, setStyleLoaded, loaded, setLoaded] = useMapbox({
    zoom,
    center,
    mapContainer,
    typeMap,
  });

  const draw = useMapboxDraw({ map, typeMap });

  function changeStyleMap(style) {
    if (typeMap !== style) {
      setTypeMap(style);
      setLoaded(false);
      setStyleLoaded(false);
    }
  }

  useEffect(() => {
    map.current.resize();
  }, [size]);

  useEffect(() => {
    if (!styleLoaded || !loaded) return;
    if (map?.current?.getSource('share_label') || map?.current?.getLayer('share_label')) return;

    map.current.addSource('share_label', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }, [map, styleLoaded, loaded]);

  useEffect(() => {
    if (!draw || !styleLoaded || !loaded) return;
    draw.deleteAll();

    geozones
      .map((geozone) => {
        return {
          type: 'Feature',
          geometry: geozone.geometry,
          id: geozone._id,

          properties: {
            calculatedArea: geozone.area,
            name: geozone.name,
            //class_id: currentGeozone._id === geozone._id ? 4 : '3',
            class_id: geozone.color,
          },
        };
      })
      .forEach((geozone) => draw.add(geozone));

    const geozoneLabels = [];

    const all = draw.getAll();
    if (all && all.features) {
      all.features.forEach(function (feature) {
        if (feature.geometry.coordinates.length > 0 && feature.geometry.coordinates[0].length > 3) {
          const point = turf.centroid(feature, {
            type: 'area',
            label: 'm²',
          });
          point.properties = { label: feature.properties.name };

          geozoneLabels.push(point);
        }
      });
    }

    if (map.current.getLayer('share_label')) map.current.removeLayer('share_label');

    map.current.addLayer({
      id: 'share_label',
      source: 'share_label',
      type: 'symbol',
      paint: {
        'text-color': typeMap === VECTOR ? 'black' : 'white',
      },
      layout: {
        'text-field': '{label}',
        'text-size': 12,
      },
    });

    // measurements layer

    map.current.getSource('share_label').setData({
      type: 'FeatureCollection',
      features: geozoneLabels,
    });
  }, [draw, geozones, currentGeozone, styleLoaded, loaded]);

  useEffect(() => {
    if (!map) return;

    map.current.on('zoomend', () => {
      setZoom(map.current.getZoom());
      setCenter(map.current.getCenter());
    });
  }, [map, setZoom, setCenter]);

  useEffect(() => {
    if (!currentGeozone?.center) return;

    console.log({ center: [currentGeozone.center.lng, currentGeozone.center.lat] });
    map.current.setCenter([currentGeozone.center.lng, currentGeozone.center.lat]);
  }, [currentGeozone]);

  return (
    <div>
      <div
        ref={mapContainer}
        style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}
      />
      <Button
        variant={typeMap === SATELLITE ? 'contained' : 'outlined'}
        size="small"
        color="primary"
        style={{ position: 'absolute', top: 16, left: 16, cursor: 'pointer' }}
        onClick={() => changeStyleMap(SATELLITE)}
      >
        Спутник
      </Button>
      <Button
        variant={typeMap === VECTOR ? 'contained' : 'outlined'}
        size="small"
        color="primary"
        style={{ position: 'absolute', top: 16, left: 110, cursor: 'pointer' }}
        onClick={() => changeStyleMap(VECTOR)}
      >
        Вектор
      </Button>
    </div>
  );
};

export default GeozoneEditor;
