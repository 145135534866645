import React, { useEffect } from 'react';
import { useGetAllAPI } from '../../api/use-geozone-api';
import { useDispatch, useSelector } from 'react-redux';

import { List, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

import LoaderProgress from '../circular-progress-center';

import Geozone from './geozone';

const LoadingContainer = (props) => (
  <LoaderProgress justify="center" alignItems="center" style={{ marginTop: 20 }} />
);

const GeozoneList = () => {
  const dispatch = useDispatch();
  const { geozones, currentGeozone, measureMode } = useSelector((state) => state);

  const { data, isLoading, error } = useGetAllAPI();

  useEffect(() => {
    if (!data) return;
    dispatch({ type: 'LOAD_GEOZONES', value: data });
  }, [data, dispatch]);

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? <LoadingContainer /> : null}
      {!isLoading && error && (
        <div style={{ color: 'red', padding: 16, textAlign: 'center' }}>Ошибка загрузки геозон</div>
      )}

      <List component="nav" style={{ overflowY: 'scroll', width: '100%' }}>
        {geozones.length === 0 && !error ? (
          <ListItem>
            <ListItemText secondary="Нет созданных геозон"></ListItemText>
          </ListItem>
        ) : null}
        {geozones.map((geozone) => (
          <Geozone
            key={geozone._id}
            geozone={geozone}
            currentGeozone={currentGeozone}
            dispatch={dispatch}
            measureMode={measureMode}
          />
        ))}
      </List>
    </div>
  );
};

export default GeozoneList;
