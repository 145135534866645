import { useQuery } from 'react-query';
import { NotificationManager } from 'react-notifications';

import { trackLogAPI } from '../../api-controller/tracklog-controller';

export const useAPI = ({ date, transport_id }) => {
  const {
    isLoading,
    error,
    refetch,
    data: trackLogs,
  } = useQuery(
    ['trackLogs', { date, transport_id }],
    () => trackLogAPI.all({ date, transport_id }),
    {
      keepPreviousData: true,
      onError: (error) => {
        NotificationManager.error(error.message);
      },
    },
  );

  return { isLoading, error, refetch, trackLogs };
};
