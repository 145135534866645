import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  date: {
    margin: 4,
  },
});

const ReportCalendar = (props) => {
  const { initDate } = useSelector((state) => state);

  const [date, setDate] = useState(moment(new Date()).startOf('day').utc().toISOString());

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'setDate',
      value: date,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    if (!initDate) return;
    setDate(initDate);
  }, [dispatch, initDate]);

  const handleDateChange = (date) => {
    setDate(moment(date).startOf('day').utc().toISOString());
  };

  return (
    <div style={{ margin: 16, overflow: 'hidden' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item lg={12}>
            {`Отчет по транспортному средству за `}
            {moment(date).format('DD.MM.YYYY')}
          </Grid>
          <Grid item lg={12}>
            <KeyboardDatePicker
              label="Дата"
              margin="normal"
              format="d MMMM"
              id="mui-pickers-date"
              value={date}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Tooltip title="Статистика за сегодняшний день.">
              <Button
                color="primary"
                variant="text"
                size="small"
                onClick={() => {
                  setDate(moment(new Date()).startOf('day').utc().toISOString());
                }}
              >
                Сегодня
              </Button>
            </Tooltip>
            <Tooltip title="Статистика за вчерашний день.">
              <Button
                variant="text"
                color="primary"
                size="small"
                onClick={() => {
                  setDate(
                    moment(new Date()).startOf('day').utc().subtract(1, 'days').toISOString(),
                  );
                }}
              >
                Вчера
              </Button>
            </Tooltip>

            <Button
              color="primary"
              variant="text"
              size="small"
              onClick={() => {
                setDate(moment(new Date()).startOf('day').utc().subtract(2, 'days').toISOString());
              }}
            >
              Позавчера
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withStyles(styles)(ReportCalendar);
