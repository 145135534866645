import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import io from 'socket.io-client';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';

const NAMESPACE_LOCATOR_SOCKET_URL = process.env.REACT_APP_SOCKET_IO_LOCATOR;

export const useSocket = () => {
  const dispatch = useDispatch();

  const { keycloak } = useKeycloak();

  const [socket] = useState(() => {
    return io(NAMESPACE_LOCATOR_SOCKET_URL, {
      forceNew: true,
      autoConnect: true,
    });
  });

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      socket.on('connect_error', (error) => {
        NotificationManager.error(error.message, 'Ошибка подключения к сокету!');
      });

      socket.on('connect', async () => {
        socket.emit('join', {
          keycloakId: userInfo.sub,
        });
      });

      socket.on('locator', (point) => {
        dispatch({
          type: 'SET_ONLINE_MARKERS',
          value: point,
        });
      });
    });

    return () => {
      if (socket) socket.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return socket;
};
