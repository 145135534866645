import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reducer } from './reducer';
import StatsTable from './stats';
import BarCharts from './bar';
import moment from 'moment';

const initialState = {
  startDate: moment(new Date()).startOf('day').utc().toISOString(),
  endDate: moment(new Date()).endOf('day').utc().toISOString(),
};

const store = createStore(reducer, initialState);

const Stats = () => {
  return (
    <Provider store={store}>
      <Typography style={{ paddingLeft: 16 }} variant="h6" id="tableTitle" component="div">
        Статистика
      </Typography>

      <BarCharts />
      <StatsTable />
    </Provider>
  );
};

export default Stats;
