import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDeleteAPI } from '../../api/use-route-api';

export const useDeleteRoute = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useDeleteAPI();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch({
        type: 'REMOVE_ROUTE',
        value: {
          id: data.response._id,
        },
      });
    }
  }, [dispatch, data, isSuccess]);

  const onCreateRoute = useCallback(
    (e) => {
      e.features.forEach((poly) => {
        mutate({
          id: poly.id,
        });
      });
    },
    [mutate],
  );

  return onCreateRoute;
};
