import { useEffect, useRef, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxLanguage from '@mapbox/mapbox-gl-language';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const useMapbox = ({ zoom, center, mapContainer, typeMap }) => {
  const map = useRef(null);
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (map.current) {
      map.current.remove();
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: typeMap,
      center: center,
      zoom: zoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.addControl(new MapboxLanguage({ defaultLanguage: 'ru' }));

    map.current.on('load', () => {
      setLoaded(true);
    });
    map.current.once('styledata', () => {
      setStyleLoaded(true);
    });
  }, [mapContainer, typeMap]);

  return [map, styleLoaded, setStyleLoaded, loaded, setLoaded];
};
