import React, { useEffect, useState } from 'react';
import SplitPane from 'react-split-pane';
import { useSelector } from 'react-redux';
import TransportList from '../../components/transport-list';
import Map from './map';
import './index.css';

const SplitPanels = () => {
  const [size, setSize] = useState(
    parseInt(localStorage.getItem('splitPosTransportLocator'), 10) || '30%',
  );

  const { onlineMarkers, panelSize } = useSelector((state) => state);

  useEffect(() => {
    localStorage.setItem('splitPosTransportLocator', size);
  }, [size]);

  const [proxySize, setProxySize] = useState(size);

  return (
    <SplitPane split="vertical" minSize={0} style={{ height: 'calc(100% - 66px)' }}>
      <div />
      <SplitPane
        split="vertical"
        primary="first"
        defaultSize={size}
        size={panelSize}
        onChange={(size) => setSize(size)}
        minSize={32}
        onDragFinished={() => setProxySize(size)}
      >
        <TransportList
          emptyMessage="Нет транспортных средств в онлайне"
          modelName="transport"
          onlineMarkers={onlineMarkers}
        />
        <Map size={proxySize} />
      </SplitPane>
    </SplitPane>
  );
};

export default SplitPanels;
