import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import LiveAgroScreen from './images/live-agro.png';

const TELEGRAM_CHAT_BOT_URL = process.env.REACT_APP_TELEGRAM_CHAT_BOT_URL;

const TelegramBotPage = (props) => {
  const { keycloak } = useKeycloak();

  const [userId, setUserId] = useState();

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo) => {
      setUserId(userInfo.sub);
    });
  }, []);

  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{ padding: 16 }}>
      <Grid item lg={12}>
        <center>
          <img width={300} src={LiveAgroScreen} />
        </center>
        Чтобы подключится к телеграм-боту, нужно зарегистрироваться у него и получить от него
        ссылку, на которую нужно будет использовать для активации подписки:
        <center>
          <a href={`${TELEGRAM_CHAT_BOT_URL}?start=${userId}`} style={{ textDecoration: 'none' }}>
            <Button
              style={{
                backgroundColor: 'rgb(50,175,237)',
                color: 'white',
                margin: 10,
              }}
              variant="contained"
            >
              Открыть в телеграм
            </Button>
          </a>
        </center>
      </Grid>
    </Grid>
  );
};

export default TelegramBotPage;
