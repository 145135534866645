export const trackColor = (speed) => {
  if (speed === 0) {
    return 'grey';
  } else if (speed < 20) {
    return 'red';
  } else if (speed < 40) {
    return '#FFFF00';
  } else if (speed < 80) {
    return '#66CC00';
  } else {
    return 'orange';
  }
};

export const trackLightColor = (speed) => {
  if (speed === 0) {
    return '#F0F0F0';
  } else if (speed < 20) {
    return '#FFCCCC';
  } else if (speed < 40) {
    return '#FFFFCC';
  } else if (speed < 80) {
    return '#CCFFCC';
  } else {
    return 'orange';
  }
};
