import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

const Logout = () => {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.logout({ redirectUri: window.location.origin });
    }
  }, []);

  return <div>Выход...</div>;
};

export default Logout;
