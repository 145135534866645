import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateAPI } from './use-api';

export const useUpdateTransport = () => {
  const dispatch = useDispatch();

  const { mutate, isLoading, data, isSuccess } = useUpdateAPI();

  const [isComplete, setComplete] = useState(false);

  const [transport, setTransport] = useState();

  useEffect(() => {
    if (data && isSuccess) {
      const { data: response } = data;

      if (response.success) {
        setComplete(true);
      } else {
        setComplete(false);
      }
    }
  }, [dispatch, data, isSuccess]);

  const onUpdateTransport = useCallback(
    (props) => {
      setTransport(props);
      mutate({
        id: props._id,
        transport: props,
      });
    },
    [mutate],
  );

  return { onUpdateTransport, isLoading, isComplete, transport, setComplete };
};
