export const colors = [
  '#9c27b0',
  '#e91e63',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#009688',
  '#00bcd4',
  '#cddc39',
  '#8bc34a',
  '#ff9800',
];
