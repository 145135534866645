import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reducer } from './reducer';
import TransportTable from './transport-table';

const initialState = {
  transports: [],
  loading: false,
};

const store = createStore(reducer, initialState);

const TransportList = () => {
  return (
    <Provider store={store}>
      <TransportTable />
    </Provider>
  );
};

export default TransportList;
