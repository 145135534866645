import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';

import { useUpdateAPI } from '../../api/use-route-api';
import SelectColors from '../select-color';
import SelectDirections from './select-route-direction';
import { useSelector, useDispatch } from 'react-redux';

const styles = (theme) => ({
  textField: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function valuetext(value) {
  return `${value} м`;
}

function EditForm(props) {
  const dispatch = useDispatch();
  const { classes } = props;

  const { currentRoute } = useSelector((state) => state);

  const { mutate, isLoading, isSuccess, data } = useUpdateAPI();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch({
        type: 'UPDATE_ROUTE',
        value: {
          id: data.response._id,
          route: data.response,
        },
      });
    }
  }, [dispatch, data, isSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: currentRoute.name,
        id: currentRoute._id,
        departure: currentRoute.departure || 20,
        allowedParkingDuration: currentRoute.allowedParkingDuration || 5,
        allowMaxSpeed: currentRoute.allowMaxSpeed || 60,
        color: currentRoute.color || '#ff9800',
        direction: currentRoute.direction || 'both',
        groups: currentRoute.groups,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(128, 'Слишком длинное имя').required('Введите имя маршрута'),
      })}
      onSubmit={async (values) => {
        mutate({ id: values.id, body: values });
      }}
      render={({
        values,
        touched,
        errors,
        handleSubmit,
        handleBlur,
        handleChange,
        setValues,
        isSubmitting,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{ padding: 16 }}
            >
              <Typography>Настройки маршрута</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="id"
                    name="id"
                    disabled
                    value={values.id || ''}
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    error={!!errors.name}
                    helperText={errors.name}
                    type="text"
                    value={values.name || ''}
                    onChange={handleChange}
                    name="name"
                    label="Имя маршрута"
                    fullWidth
                    className={classes.textField}
                    aria-valuetext="ee"
                    margin="normal"
                  />
                  <Grid item xs={12}>
                    <InputLabel htmlFor="age-customized-select">
                      Отклонение от маршрута {values.departure} м
                    </InputLabel>
                    <Slider
                      step={2}
                      min={20}
                      max={50}
                      name="departure"
                      onChange={(e, val) => setValues({ ...values, departure: val })}
                      value={values.departure}
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider"
                    />
                  </Grid>
                  <InputLabel htmlFor="age-customized-select">Цвет маршрута:</InputLabel>
                  <SelectColors errors={errors} values={values} handleChange={handleChange} />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor="age-customized-select">
                    Допустимое время стоянки {values.allowedParkingDuration} мин
                  </InputLabel>
                  <Slider
                    step={1}
                    min={2}
                    max={60}
                    name="departure"
                    onChange={(e, val) =>
                      setValues({
                        ...values,
                        allowedParkingDuration: val,
                      })
                    }
                    value={values.allowedParkingDuration}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="age-customized-select">
                    Максимальная скорость {values.allowMaxSpeed} км/ч
                  </InputLabel>
                  <Slider
                    step={1}
                    min={1}
                    max={140}
                    name="departure"
                    onChange={(e, val) =>
                      setValues({
                        ...values,
                        allowMaxSpeed: val,
                      })
                    }
                    value={values.allowMaxSpeed}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="age-customized-select">
                    Допустимое движение по маршруту
                  </InputLabel>

                  <SelectDirections errors={errors} values={values} handleChange={handleChange} />
                </Grid>

                <Grid item xs={12}>
                  {values.groups &&
                    values.groups.map((group) => (
                      <Chip
                        key={group._id}
                        label={group.name}
                        size="small"
                        color="primary"
                        variant="outlined"
                        style={{
                          margin: 1,
                          fontSize: 10,
                        }}
                      />
                    ))}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={values.id ? false : true}
                  >
                    {isLoading ? (
                      <CircularProgress
                        className={classes.facebook}
                        variant="indeterminate"
                        disableShrink
                        size={24}
                        thickness={4}
                        style={{ marginRight: 10 }}
                      />
                    ) : null}
                    Обновить маршрут
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
}

export default withStyles(styles)(EditForm);
