import { createMuiTheme } from '@material-ui/core/styles';

export const themeGreen = createMuiTheme({
  palette: {
    primary: {
      main: '#66bb6a',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f50057',
    },
  },
});
