import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

export const Loader = ({ text }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />

      <div>{text}</div>
    </div>
  );
};

export default Loader;
