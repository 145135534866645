import * as Yup from 'yup';

export const CreateOrUpdateSchema = Yup.object().shape({
  imei: Yup.string('Должны быть только числа')
    .min(15, 'Минимум 15 чисел')
    .max(15, 'Максимум 15 чисел')
    .required('Введите IMEI'),
  phone: Yup.string()
    .min(9, 'Минимум 9 символов')
    .max(14, 'Максимум 14 символов')
    .required('Введите телефон'),
  brand: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(20, 'Максимум 20 символов')
    .required('Введите бренд'),
  model: Yup.string()
    .min(3, 'Минимум 3 символа')
    .max(20, 'Максимум 20 символов')
    .required('Введите модель транспортного средства'),
  number: Yup.string()
    .min(8, 'Минимум 8 символов')
    .max(20, 'Максимум 20 символов')
    .required('Введите гос. номер'),
});
