import React, { useEffect } from 'react';
import { List, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector, useDispatch } from 'react-redux';

import Route from './route';
import { useGetAllAPI } from '../../api/use-route-api';

const RouteList = (props) => {
  const dispatch = useDispatch();
  const { routes, currentRoute } = useSelector((state) => state);

  const { data, isLoading, error } = useGetAllAPI();

  useEffect(() => {
    if (!data) return;
    dispatch({ type: 'LOAD_ROUTES', value: data });
  }, [data, dispatch]);

  return (
    <List component="nav" style={{ overflowY: 'auto', width: '100%' }}>
      {routes.length === 0 ? (
        <ListItem>
          <ListItemText secondary="Нет добавленных маршрутов"></ListItemText>
        </ListItem>
      ) : null}
      {routes.map((route) => (
        <Route key={route._id} route={route} currentRoute={currentRoute} dispatch={dispatch} />
      ))}
    </List>
  );
};

export default RouteList;
