import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import SplitPanels from './split-panels';
import { reducer } from './reducer';

const initialState = {
  mapPoint: {
    lat: 49.442816,
    lng: 32.0621061,
  },
  mapZoom: 15,
  geozones: [],
  routes: [],
  onlineMarkers: [],
  transports: [],
  currentTransport: {},
  loading: false,
  initTransports: [],
  panelSize: parseInt(localStorage.getItem('splitPosTransportLocator'), 10) || '30%',
};

const store = createStore(reducer, initialState);

const TransportLocator = () => {
  return (
    <Provider store={store}>
      <SplitPanels/>
    </Provider>
  );
};

export default TransportLocator;
