import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import Button from '@material-ui/core/Button';
import { FormControl, Grid, MenuItem } from '@material-ui/core';
import { useBarAPI, useGetTrackYears } from './use-api';
import 'moment/locale/ru';

import moment from 'moment';
import Select from '@material-ui/core/Select';
import Show from '../../components/show';

moment.locale('ru');

var getDaysArray = function(year, month) {
  var monthIndex = month - 1; // 0..11 instead of 1..12
  var names = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  var date = new Date(year, monthIndex, 1);
  var result = [];
  while (date.getMonth() === monthIndex) {
    result.push(date.getDate() + ' ' + names[date.getDay()]);
    date.setDate(date.getDate() + 1);
  }
  return result;
};

var getOnlyDaysArray = function(year, month) {
  var monthIndex = month - 1; // 0..11 instead of 1..12
  var date = new Date(year, monthIndex, 1);
  var result = [];
  while (date.getMonth() === monthIndex) {
    result.push(date.getDate());
    date.setDate(date.getDate() + 1);
  }
  return result;
};

const options = {
  indexAxis: 'x',

  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Ноябрь 2021 (статистика по дням)',
    },
  },
};

const BarCharts = () => {
  const [data, setData] = useState({ datasets: [] });
  const [transportKeys, setTransportKeys] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment().format('M'));
  const [year, setYear] = useState();

  const history = useHistory();

  const getElementAtEvent = (element) => {
    if (!element.length) return;
    const { index } = element[0];
    const days = getOnlyDaysArray(moment().year(), currentMonth);
    const day = moment(`${year ? year : moment().year()}-${currentMonth}-${days[index]}`, 'YYYY-M-D')
      .startOf('day')
      .utc()
      .toISOString();
    if (currentFilter) {
      history.push(`tracks/${currentFilter}/${day}`);
    }
  };

  const { data: yearList, isLoading: isYearsLoading, error } = useGetTrackYears();


  const { stats, isLoading } = useBarAPI({
    date: `${year ? year : moment().year()}-${currentMonth}`,
    transportId: currentFilter,
  });

  useEffect(() => {
    if (!stats) return;
    options.plugins.title.text =
      'Всего за месяц: ' +
      stats.stats.reduce((prev, current) => prev + current.distance, 0).toFixed(2) +
      ' км';
  }, [currentMonth, stats]);

  useEffect(() => {
    if (stats && !isLoading) {
      const days = getOnlyDaysArray(year ? year : moment().year(), currentMonth);

      setTransportKeys(stats.transports);
      const data = {
        labels: getDaysArray(year ? year : moment().year(), currentMonth),
        datasets: [
          {
            label: 'Дистанция (км)',
            data: days.map((day) => {
              const stat = stats.stats.find((s) => {
                return moment(
                  moment(`${year ? year : moment().year()}-${currentMonth}-${day}`, 'YYYY-M-D').format(
                    'YYYY-MM-DD',
                  ),
                ).isSame(moment(s._id.day).format('YYYY-MM-DD'));
              });
              if (stat) {
                return stat.distance.toFixed(2);
              } else {
                return 0;
              }
            }),
            backgroundColor: ['rgb(67, 160, 71)'],
            borderWidth: 1,
          },
        ],
      };
      setData(data);
    }
  }, [stats, isLoading]);

  function handleYearChange(event) {
    setYear(event.target.value);
  }

  return (
    <div>

      <Button
        color='primary'
        variant={null === currentFilter ? 'contained' : 'outlined'}
        style={{ margin: 4 }}
        onClick={() => {
          setCurrentFilter(null);
        }}
      >
        Все
      </Button>
      {Object.keys(transportKeys).map((transport) => (
        <Button
          color='primary'
          variant={transportKeys[transport]._id === currentFilter ? 'contained' : 'outlined'}
          style={{ margin: 4 }}
          onClick={() => {
            setCurrentFilter(transportKeys[transport]._id);
          }}
        >
          {transportKeys[transport].model}
        </Button>
      ))}

      <div>
        <Show condition={yearList?.length > 0}>
          <FormControl variant='outlined'>
            <Select
              style={{height: 36, marginTop: 4, marginLeft: 6}}
              value={year ? year : yearList && yearList[yearList.length - 1]}
              onChange={handleYearChange}
            >
              {yearList && yearList.map(year => {
                return <MenuItem value={year}>{year}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Show>
        {moment.months().map((m) => {
          return (
            <Button
              color='primary'
              variant={moment(m, 'MMMM').format('M') === currentMonth ? 'contained' : 'outlined'}
              style={{ margin: 4 }}
              onClick={() => {
                setCurrentMonth(moment(m, 'MMMM').format('M'));
              }}
            >
              {m}
            </Button>
          );
        })}
      </div>
      <Grid container direction='row' justify='center' alignItems='center' style={{ padding: 32 }}>
        <Grid item md={9}>
          <Bar getElementAtEvent={getElementAtEvent} data={data} options={options} />
        </Grid>
      </Grid>
    </div>
  );
};

export default BarCharts;
