import React from 'react';

export const useStateWithLocalStorage = ({ key, defaultValue }) => {
  const [value, setValue] = React.useState(JSON.parse(localStorage.getItem(key)) || defaultValue);
  React.useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
    // eslint-disable-next-line
  }, [value]);
  return [value, setValue];
};

export const useStateWithSessionStorage = ({ key, defaultValue }) => {
  const [value, setValue] = React.useState(JSON.parse(sessionStorage.getItem(key)) || defaultValue);
  React.useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
    // eslint-disable-next-line
  }, [value]);
  return [value, setValue];
};
