import axios from 'axios';

export const getTransportEndpoint = () => {
  return process.env.REACT_APP_ENDPOINT + '/api/v1/transport';
};

export const transportAPI = {
  all: async () => {
    const { data } = await axios.get(getTransportEndpoint());
    return data;
  },

  create: async ({ transport }) => {
    return axios({
      method: 'post',
      url: getTransportEndpoint(),
      data: transport,
    });
  },
  update: async ({ id, transport }) => {
    return axios({
      method: 'put',
      url: getTransportEndpoint() + '/' + id,
      data: transport,
    });
  },
  delete: async ({ id }) => {
    return axios.delete(getTransportEndpoint() + '/' + id);
  },
};
