import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCreateRoute } from './use-create-geozone';
import { useUpdateRoute } from './use-update-geozone';
import { useDeleteRoute } from './use-delete-geozone';

import { colors } from '../../components/select-color/color';

// case для цветов background

const fillColor = colors.reduce((acc, color) => {
  return [...acc, ['==', ['get', 'user_class_id'], color], color];
}, []);

export const useMapboxDraw = ({ map, typeMap }) => {
  const [draw, setDraw] = useState();

  const dispatch = useDispatch();

  const onCreateRoute = useCreateRoute();
  const onUpdateRoute = useUpdateRoute();
  const onDeleteRoute = useDeleteRoute();

  useEffect(() => {
    if (!map.current) return;

    const draw_ = new window.MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,

      controls: {
        line_string: true,
        trash: true,
      },

      defaultMode: 'simple_select',

      styles: [
        {
          id: 'highlight-active-points',
          type: 'circle',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],

          paint: {
            'circle-radius': 7,
            'circle-color': '#000000',
          },
        },
        {
          id: 'points-are-blue',
          type: 'circle',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          paint: {
            'circle-radius': 5,
            'circle-color': ['case', ...fillColor, 'silver'],
          },
        },
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': ['case', ...fillColor, 'silver'],
            'line-dasharray': [0.3, 2],
            'line-width': 3,
          },
        },
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 5,
            'circle-color': 'green',
          },
        },
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': 'black',
            'line-width': 1,
            'line-opacity': 0.5,
          },
        },

        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': 'black',
          },
        },

        {
          id: 'gl-draw-polygon-and-line-vertex-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': 'green',
          },
        },

        {
          id: 'gl-draw-line-static',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 3,
          },
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill-static',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
          paint: {
            'fill-color': '#000',
            'fill-outline-color': '#000',
            'fill-opacity': 0.1,
          },
        },
        // polygon outline
        {
          id: 'gl-draw-polygon-stroke-static',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 3,
          },
        },
      ],
    });

    setDraw(draw_);

    map.current.addControl(draw_);

    map.current.on('draw.create', onCreateRoute);
    map.current.on('draw.delete', onDeleteRoute);
    map.current.on('draw.update', onUpdateRoute);

    map.current.on('draw.modechange', onModeChange);

    map.current.on('draw.selectionchange', (e) => {
      if (e.features[0]) {
        dispatch({ type: 'SET_CURRENT_ROUTE_BY_ID', value: e.features[0].id });
      }
    });
    function onModeChange(e) {
      console.log({ e });
    }
  }, [map, typeMap]);

  return draw;
};
