import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AlarmIcon from '@material-ui/icons/Notifications';
import AlarmActiveIcon from '@material-ui/icons/NotificationsActive';

import FilterListIcon from '@material-ui/icons/FilterList';
import { CreateTransport } from './create-transport';
import { UpdateTransport } from './update-transport';

import { useDeleteTransport } from './use-delete-transport';
import { useUpdateTransport } from './use-update-transport';

import { useGetAllAPI } from './use-api';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: '_id', numeric: false, disablePadding: false, label: 'ID' },
  { id: 'imei', numeric: true, disablePadding: false, label: 'IMEI' },
  { id: 'phone', numeric: true, disablePadding: false, label: 'Моб. телефон' },
  { id: 'brand', numeric: true, disablePadding: false, label: 'Бранд' },
  { id: 'model', numeric: true, disablePadding: false, label: 'Модель' },
  { id: 'number', numeric: true, disablePadding: false, label: 'Гос. номер' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key="buttons" align="center" padding="normal">
          Управление
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  const { setShowCreateDialog, setCurrentTransport } = props;

  return (
    <>
      <Toolbar className={clsx(classes.root)}>
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Транспортные средства
        </Typography>

        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <div style={{ paddingLeft: 10 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentTransport({
              imei: '',
              phone: '',
              brand: '',
              model: '',
              number: '',
            });
            setShowCreateDialog(true);
          }}
        >
          Добавить
        </Button>
      </div>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const { transports } = useSelector((state) => state);

  const { transports: transportList, isLoading, error } = useGetAllAPI();
  const {
    onUpdateTransport,
    isLoading: isProgressUpdate,
    isComplete,
    setComplete,
    transport,
  } = useUpdateTransport();

  useEffect(() => {
    if (!isProgressUpdate && isComplete) {
      dispatch({ type: 'UPDATE_TRANSPORT', value: transport });

      setComplete(false);
    }
  }, [isComplete, isProgressUpdate]);

  const { onDeleteRoute } = useDeleteTransport();

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [rows, setRows] = useState([]);

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const [currentTransport, setCurrentTransport] = useState({
    imei: '',
    phone: '',
    brand: '',
    model: '',
    number: '',
  });

  useEffect(() => {
    setRows(transports?.length ? transports.map((t) => ({ ...t, id: t._id })) : []);
  }, [transports]);

  useEffect(() => {
    dispatch({
      type: 'LOAD_TRANSPORTS',
      value: transportList,
    });
  }, [dispatch, transportList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      <CreateTransport
        open={showCreateDialog}
        initValue={currentTransport}
        handleClose={() => {
          setShowCreateDialog(false);
        }}
      />

      <UpdateTransport
        open={showUpdateDialog}
        initValue={currentTransport}
        handleClose={() => {
          setShowUpdateDialog(false);
        }}
      />
      <div className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setShowCreateDialog={setShowCreateDialog}
          setCurrentTransport={setCurrentTransport}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.name}
                    >
                      <TableCell component="th" scope="row">
                        {row._id}
                      </TableCell>
                      <TableCell align="right">{row.imei}</TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell align="right">{row.brand}</TableCell>
                      <TableCell align="right">{row.model}</TableCell>
                      <TableCell align="right">{row.number}</TableCell>
                      <TableCell align="center">
                        {row.notify ? (
                          <AlarmActiveIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              onUpdateTransport({ ...row, notify: !row.notify });
                            }}
                          />
                        ) : (
                          <AlarmIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              onUpdateTransport({ ...row, notify: !row.notify });
                            }}
                          />
                        )}
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setCurrentTransport(row);
                            setShowUpdateDialog(true);
                          }}
                        />
                        <DeleteIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (
                              window.confirm('Вы уверены что хотите удалить транспортное средство?')
                            ) {
                              onDeleteRoute(row.id);
                            }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
