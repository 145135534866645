import _ from 'lodash';

export const reducer = (state, action) => {
  let index, newState;

  switch (action.type) {
    case 'SET_MAP_POINT':
      return {
        ...state,
        mapPoint: action.value.mapPoint,
        mapZoom: action.value.mapZoom,
      };

    case 'DELETE_MODE':
      return {
        ...state,
        deleteMode: action.value,
        geozones: state.geozones.map((zone) => ({ ...zone, color: null })),
      };

    case 'MEASURE_MODE':
      return {
        ...state,
        measureMode: action.value,
      };

    case 'LOAD_GEOZONES':
      return {
        ...state,
        geozones: action.value,
      };
    case 'CREATE_GEOZONE':
      console.log({ CREATE_GEOZONE: action.value });
      return {
        ...state,
        currentGeozone: action.value,
        geozones: [...state.geozones, action.value],
      };

    case 'REMOVE_GEOZONE':
      return {
        ...state,

        geozones: state.geozones.filter((zone) => zone._id !== action.value.id),
      };

    case 'UPDATE_GEOZONE':
      index = state.geozones.findIndex((zone) => zone._id === action.value.id);

      if (index === -1) {
        index = state.geozones.findIndex((zone) => zone._id === action.value.geozone._id);
      }
      return {
        ...state,
        geozones: [
          ...state.geozones.slice(0, index),
          action.value.geozone,
          ...state.geozones.slice(index + 1),
        ],
      };

    case 'SET_CURRENT_GEOZONE_BY_ID':
      const currentGeozone = state.geozones.find((g) => g._id === action.value);
      if (!currentGeozone) {
        return state;
      }

      return {
        ...state,
        currentGeozone,
      };
    case 'SET_CURRENT_GEOZONE':
      return {
        ...state,
        currentGeozone: { ...action.value },
      };

    case 'SET_FOR_DELETE_GEOZONE':
      index = state.geozones.findIndex((zone) => zone._id === action.value);
      const newGeozones = state.geozones.map((zone) => {
        //delete zone.color;
        return zone;
      });
      return {
        ...state,
        currentGeozone: newGeozones[index],
        geozones: [
          ...newGeozones.slice(0, index),
          { ...newGeozones[index], color: 'red' },
          ...newGeozones.slice(index + 1),
        ],
      };
    default:
      return state;
  }
};
