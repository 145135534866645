import axios from 'axios';

export const endpoint = process.env.REACT_APP_ENDPOINT + '/api/v1/tracklog-stats';

export const trackLogStatsAPI = {
  all: async ({ startDate, endDate }) => {
    const { data } = await axios.get(endpoint, {
      params: { startDate, endDate },
    });
    return data;
  },

  getYears: async () => {
    const { data } = await axios.get(endpoint + '/years');
    return data;
  },

  daysByMonth: async ({ date, transportId }) => {
    const { data } = await axios.get(endpoint + '/stats-by-month', {
      params: { date, transportId },
    });
    return data;
  },
};
