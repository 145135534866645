import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

export const sprintGPSDatetime = (date) => {
  return moment.utc(date).local().format('HH:mm:ss DD.MM');
};

export const sprintDuration = (first, last) => {
  const duration = moment.duration(moment.utc(last).diff(moment.utc(first)));
  return {
    print: duration.format('h[ч] m[м] s[с]'),
    minutes: duration.asMinutes(),
  };
};

export const sprintDurationWith = (first, last, additional) => {
  const duration = moment.duration(moment.utc(last).diff(moment.utc(first))).add(additional);
  return {
    print: duration.format('h[ч] m[м] s[с]'),
    minutes: duration.asMinutes(),
  };
};

export const betweenDuration = (first, last) => {
  return moment.duration(moment.utc(last).diff(moment.utc(first)));
};

export const utcToLocale = (date) => {
  return moment.utc(date).local().format('HH:mm:ss DD.MM.YYYY');
};
