export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MAP_POINT':
      if (action.value.mapZoom) {
        return {
          ...state,
          mapPoint: action.value.mapPoint,
          mapZoom: action.value.mapZoom,
        };
      } else {
        return {
          ...state,
          mapPoint: action.value.mapPoint,
        };
      }
    case 'LOAD_ROUTES':
      return {
        ...state,
        routes: action.value,
      };

    case 'LOAD_GEOZONES':
      return {
        ...state,
        geozones: action.value,
      };

    case 'setPanelSize':
      return {
        ...state,
        panelSize: action.value,
      };

    case 'MOVE_MARKERS':
      return {
        ...state,
        onlineMarkers: state.onlineMarkers.map((marker) => {
          return {
            ...marker,
            location: {
              ...marker.location,
              coordinates: [
                marker.location.coordinates[0] + 0.0001,
                marker.location.coordinates[1] + 0.0001,
              ],
            },
          };
        }),
      };

    case 'SET_ONLINE_MARKERS':
      const withoutCurrentMarker = state.onlineMarkers.filter(
        (marker) => marker.transport_id !== action.value.transport_id,
      );
      const onlineTransports = [...withoutCurrentMarker, action.value];

      return {
        ...state,
        onlineMarkers: onlineTransports,
      };

    case 'LOAD_TRANSPORTS':
      return {
        ...state,
        initTransports: action.value,
      };

    case 'SET_CURRENT_TRANSPORT':
      return {
        ...state,
        currentTransport: action.value,
      };

    case 'SET_START_DATE':
      return {
        ...state,
        startDate: action.value,
      };

    case 'SET_END_DATE':
      return {
        ...state,
        endDate: action.value,
      };

    default:
      return state;
  }
};
