import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";

import { colors } from "./color.js";
import "./style.css";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

export default function SelectColor(props) {
  const { errors, values, handleChange } = props;

  return (
    <Select
      error={!!errors.color}
      name="color"
      fullWidth
      value={values.color || ""}
      onChange={handleChange}
      input={<BootstrapInput name="age" id="age-customized-select" />}
    >
      {colors.map(color => {
        return (
          <MenuItem value={color} key={color}>
            <div className="color-box" style={{ background: color }} />
            <div style={{ marginTop: 7 }}>{color}</div>
          </MenuItem>
        );
      })}
    </Select>
  );
}
