import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NotificationContainer } from 'react-notifications';
import { Provider } from 'react-redux';
import { App } from './app/app';
import { themeGreen } from './core/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { setKeyCloak } from './data/network/http';

import 'mapbox-gl-controls/lib/controls.css';
import 'react-notifications/lib/notifications.css';
import store from './store';
import axios from 'axios';
import keycloak from './keycloak';
import { CssBaseline } from '@material-ui/core';

axios.interceptors.request.use(
  (request) => {
    request.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const queryClient = new QueryClient();

function Index() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={(tokens) => {
        setKeyCloak(tokens);
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <MuiThemeProvider theme={themeGreen}>
            <CssBaseline />
            <App />
            <NotificationContainer />
          </MuiThemeProvider>
        </Provider>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}

export default Index;
