export const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_TRANSPORTS':
      return {
        ...state,
        transports: action.value,
      };

    case 'ADD_TRANSPORT':
      return {
        ...state,
        transports: [action.value, ...state.transports],
      };

    case 'REMOVE_TRANSPORT':
      return {
        ...state,
        transports: state.transports.filter((t) => t._id !== action.value.id),
      };

    case 'UPDATE_TRANSPORT':
      return {
        ...state,
        transports: state.transports.map((t) => {
          return t._id === action.value.id ? action.value : t;
        }),
      };
    default:
      return state;
  }
};
