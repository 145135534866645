import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';
import { NotificationManager } from 'react-notifications';

const styles = (theme) => ({
  date: {
    margin: 4,
  },
});

const ReportCalendar = () => {
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('day').utc().toISOString());
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('day').utc().toISOString());

  const firstRender = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    dispatch({
      type: 'SET_START_DATE',
      value: startDate,
    });
    dispatch({
      type: 'SET_END_DATE',
      value: endDate,
    });
  }, [startDate, endDate]);

  const handleStartDateChange = (date) => {
    if (moment(date).isAfter(endDate)) {
      NotificationManager.error('Начальная дата не может быть после конечной!', 'Ошибка!');
      return;
    }
    setStartDate(moment(date).startOf('day').utc().toISOString());
  };

  const handleEndDateChange = (date) => {
    if (moment(date).isBefore(startDate)) {
      NotificationManager.error('Конечная дата не может быть пере начальной!', 'Ошибка!');
      return;
    }
    setEndDate(moment(date).endOf('day').utc().toISOString());
  };

  return (
    <div style={{ margin: 16, overflow: 'hidden' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item lg={12}>
            {`Отчет по транспортному средству за `}
            {moment(startDate).format('DD.MM.YYYY')}-{moment(endDate).format('DD.MM.YYYY')}
          </Grid>
          <Grid item xs={6}>
            <KeyboardDatePicker
              label="Начальная дата"
              margin="normal"
              format="d MMMM"
              id="mui-pickers-date"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ paddingRight: 4 }}
            />

            <KeyboardDatePicker
              label="Конечная дата"
              margin="normal"
              format="d MMMM"
              id="mui-pickers-date"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ paddingRight: 4 }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withStyles(styles)(ReportCalendar);
