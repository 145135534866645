import React, { useState, useEffect } from 'react';
import { useAPI } from './use-api';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Calendar from '../../components/calendar-from-to';

import FilterListIcon from '@material-ui/icons/FilterList';
import { Loader } from '../../components/loader';

const types = {
  OffRoute: 'Уход с маршрута',
  OnTheRoute: 'Заход на маршрут',
  OffGeozone: 'Уход с геозоны',
  OnTheGeozone: 'Заход на геозону',
  EngineStarting: 'Запуск двигателя',
  EngineStoping: 'Остановка двигателя',
};

function getObject(row) {
  if (row.type === 'OffRoute' || row.type === 'OnTheRoute') {
    return row.properties.route_id.name;
  } else if (row.type === 'EngineStarting' || row.type === 'EngineStoping') {
    return row.properties.lat.toFixed(5) + ' ' + row.properties.lon.toFixed(5);
  } else if (row.type === 'OffGeozone' || row.type === 'OnTheGeozone') {
    return row.properties.geozone_id.name;
  }

  return 'undefined';
}

const headCells = [
  { id: 'createdAt', numeric: true, disablePadding: false, sort: true, label: 'Дата создания' },
  { id: 'type', numeric: false, disablePadding: false, sort: true, label: 'Тип события' },
  { id: 'transport_id', numeric: false, disablePadding: false, sort: true, label: 'Модель' },
  { id: 'number', numeric: false, disablePadding: false, sort: false, label: 'Номер' },
  { id: 'object', numeric: false, disablePadding: false, sort: false, label: 'Обьект' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort === true ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <>
      <Toolbar className={clsx(classes.root)}>
        <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
          События
        </Typography>

        <Tooltip title='Filter list'>
          <IconButton aria-label='filter list'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function Events() {
  const { startDate, endDate } = useSelector((state) => state);

  const [currentFilter, setCurrentFilter] = useState(null);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('createdAt');

  const { events, isLoading } = useAPI({
    page,
    rowsPerPage,
    startDate,
    endDate,
    transportId: currentFilter,
    order,
    orderBy,
  });

  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [transportKeys, setTransportKeys] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    setPage(0);
  }, [currentFilter]);

  useEffect(() => {
    if (events && events.data && !isLoading) {
      setRows(events.data);
      setPage(events.page);
      setTotalCount(events.totalCount);
      setTransportKeys(
        events.transports.reduce((acc, row) => {
          acc[row.model] = row._id;
          return acc;
        }, {}),
      );
    }
  }, [events, isLoading]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) return <Loader text='Идет загрузка данных...' />;

  return (
    <>
      <div className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <Calendar />
        <Button
          color='primary'
          variant={null === currentFilter ? 'contained' : 'outlined'}
          style={{ margin: 4 }}
          onClick={() => {
            setCurrentFilter(null);
          }}
        >
          Все
        </Button>
        {Object.keys(transportKeys).map((transport) => (
          <Button
            color='primary'
            variant={transportKeys[transport] === currentFilter ? 'contained' : 'outlined'}
            style={{ margin: 4 }}
            onClick={() => {
              setCurrentFilter(transportKeys[transport]);
            }}
          >
            {transport}
          </Button>
        ))}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    role='checkbox'
                    tabIndex={-1}
                    key={row.name}
                  >
                    <TableCell align='center'>
                      {moment(row.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell align='center'>{types[row.type]}</TableCell>
                    <TableCell align='center'>
                      {row.transport_id.brand} {row.transport_id.model}
                    </TableCell>
                    <TableCell align='center'>{row.transport_id.number}</TableCell>
                    <TableCell align='center'>{getObject(row)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Строк на странице'}
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
