export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_START_DATE':
      return {
        ...state,
        startDate: action.value,
      };

    case 'SET_END_DATE':
      return {
        ...state,
        endDate: action.value,
      };

    default:
      return state;
  }
};
