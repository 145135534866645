import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function Params() {
  let { transportId, date } = useParams();
  const dispatch = useDispatch();

  console.log({ date });

  useEffect(() => {
    dispatch({
      type: 'SET_CURRENT_TRANSPORT',
      value: { _id: transportId },
    });
  }, [dispatch, transportId]);

  useEffect(() => {
    dispatch({
      type: 'SET_INIT_DATE',
      value: date,
    });
  }, [dispatch, date]);

  return [];
}
