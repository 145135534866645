import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  resource: process.env.REACT_APP_KEYCLOAK_RESOURCE,
  'auth-server-url': process.env.REACT_APP_KEYCLOAK_URL,
  'ssl-required': 'external',
  'public-client': true,
  'confidential-port': 0,
  'enable-cors': true,
});

export default keycloak;
