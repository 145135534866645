import { useEffect, useState } from 'react';
import { useCreateGeozone } from './use-create-geozone';
import { useUpdateGeozone } from './use-update-geozone';
import { useDeleteGeozone } from './use-delete-geozone';
import { useDispatch } from 'react-redux';
import { VECTOR, SATELLITE } from '../../core/consts';

import { colors } from '../../components/select-color/color';

// case для цветов background

const fillColor = colors.reduce((acc, color) => {
  return [...acc, ['==', ['get', 'user_class_id'], color], color];
}, []);

export const useMapboxDraw = ({ map, typeMap }) => {
  const [draw, setDraw] = useState();

  const [mode, setMode] = useState('simple_select');

  const dispatch = useDispatch();

  const onCreateGeozone = useCreateGeozone();
  const onUpdateGeozone = useUpdateGeozone();
  const onDeleteGeozone = useDeleteGeozone();

  useEffect(() => {
    if (!map.current) return;

    const draw = new window.MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,

      controls: {
        polygon: true,
        trash: true,
      },

      defaultMode: 'simple_select',

      styles: [
        {
          id: 'highlight-active-points',
          type: 'circle',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],

          paint: {
            'circle-radius': 7,
            'circle-color': '#000000',
          },
        },
        {
          id: 'points-are-blue',
          type: 'circle',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          paint: {
            'circle-radius': 5,
            'circle-color': '#000088',
          },
        },
        {
          id: 'gl-draw-line',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': 'white',
            'line-dasharray': [0.2, 2],
            'line-width': 2,
          },
        },
        {
          id: 'gl-draw-polygon-fill',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          paint: {
            'fill-color': ['case', ...fillColor, 'white'],

            'fill-outline-color': 'yellow',
            'fill-opacity': [
              'case',
              ['==', ['get', 'user_class_id'], 1],
              0.5,
              ['==', ['get', 'user_class_id'], 2],
              0.5,
              ['==', ['get', 'user_class_id'], 3],
              0.5,
              ['==', ['get', 'user_class_id'], 4],
              0.5,
              0.5,
            ],
          },
        },
        {
          id: 'gl-draw-polygon-midpoint',
          type: 'circle',
          filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
          paint: {
            'circle-radius': 3,
            'circle-color': 'green',
          },
        },
        {
          id: 'gl-draw-polygon-stroke-active',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': typeMap === VECTOR ? 'black' : 'white',
            'line-width': 1,
            'line-opacity': 0.5,
          },
        },

        {
          id: 'gl-draw-polygon-and-line-vertex-halo-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 5,
            'circle-color': 'black',
          },
        },

        {
          id: 'gl-draw-polygon-and-line-vertex-active',
          type: 'circle',
          filter: [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static'],
          ],
          paint: {
            'circle-radius': 3,
            'circle-color': 'green',
          },
        },

        {
          id: 'gl-draw-line-static',
          type: 'line',
          filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 3,
          },
        },
        // polygon fill
        {
          id: 'gl-draw-polygon-fill-static',
          type: 'fill',
          filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
          paint: {
            'fill-color': '#000',
            'fill-outline-color': '#000',
            'fill-opacity': 0.1,
          },
        },
        // polygon outline
        {
          id: 'gl-draw-polygon-stroke-static',
          type: 'line',
          filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            'line-color': '#000',
            'line-width': 3,
          },
        },
      ],
    });

    setDraw(draw);

    map.current.addControl(draw);

    map.current.on('draw.create', onCreateGeozone);
    map.current.on('draw.delete', onDeleteGeozone);
    map.current.on('draw.update', onUpdateGeozone);

    map.current.on('draw.modechange', onModeChange);

    map.current.on('draw.selectionchange', (e) => {
      console.log({ eeee: e, mode });
      if (e.features[0] && mode === 'simple_select') {
        dispatch({ type: 'SET_CURRENT_GEOZONE_BY_ID', value: e.features[0].id });
      }
    });

    function onModeChange(e) {
      setMode(e.mode);
    }
  }, [map, typeMap]);

  return draw;
};
