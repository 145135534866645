import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { CreateOrUpdateSchema } from './transport-form-validator';
import { useUpdateTransport } from './use-update-transport';

import { styles, useStyles } from './styles.js';

import { DialogFormContent } from './dialog-form-content';

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export function UpdateTransport(props) {
  const { initValue } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [transport, setTransport] = useState();

  const { onUpdateTransport, isLoading, isComplete, setComplete } = useUpdateTransport();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!isLoading && isComplete) {
      dispatch({ type: 'UPDATE_TRANSPORT', value: transport });

      setOpen(false);
      props.handleClose();
      setComplete(false);
    }
  }, [isComplete, isLoading]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValue,
    validationSchema: CreateOrUpdateSchema,
    onSubmit: (values) => {
      onUpdateTransport(values);
      setTransport(values);
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Редактирование транспортного средства
        </DialogTitle>

        <DialogFormContent formik={formik} classes={classes} />

        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Отмена
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary" variant="contained">
            Изменить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
