import React from 'react';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import SplitPanels from './split-panels';
import { reducer } from './reducer';

const initialState = {
  mapPoint: null,
  mapZoom: 15,
  geozones: [],
  currentGeozone: {},
  deleteMode: false,
  measureMode: false,
};

const store = createStore(reducer, initialState);

const GeoZoneEditor = (props) => {
  return (
    <Provider store={store}>
      <SplitPanels />
    </Provider>
  );
};

export default GeoZoneEditor;
